const patient_history_columns = [
    {
        field: 'health_complaints', 
        headerName: 'Жалобы по здоровью', 
        width: 180,
        valueGetter: (params) => {
            let values = []
            for (let i=0; i<params.value.length;i++){
                values.push(params.value[i].name)
            }
            return values.join(', ')
        }
    },
    {
        field: 'taking_medications', 
        headerName: 'Приём препаратов', 
        width: 180,
        valueGetter: (params) => {
            let values = []
            for (let i=0; i<params.value.length;i++){
                values.push(params.value[i].name)
            }
            return values.join(', ')
        }
    },
    {
        field: 'operator_request_status', 
        headerName: 'Оператор (статус обращения)', 
        width: 240,
        valueGetter: (params) => {
            if (!params.value){
                return ''
            }
            return params.value.name
        }
    },
    {
        field: 'medical_procedures', 
        headerName: 'Врачебные мероприятия', 
        width: 240,
        valueGetter: (params) => {
            if (!params.value){
                return ''
            }
            return params.value.name
        }
    },
    {
        field: 'comment_on_health_complaints', 
        headerName: 'Комментарий (жалобы)', 
        width: 250,
    },
    {
        field: 'complaints_and_suggestions_about_pmsp_work', 
        headerName: 'Замечания или пожелания по работе ПМСП', 
        width: 200,
        valueGetter: (params) => {
            let values = []
            for (let i=0; i<params.value.length;i++){
                values.push(params.value[i].name)
            }
            return values.join(', ')
        }
    },
    {
        field: 'comment_on_complaints_and_suggestions_about_pmsp_work', 
        headerName: 'Комментарий (замечания)', 
        width: 210,
    },
    {
        field: 'date_of_creation', 
        headerName: 'Дата создания', 
        width: 120,
    },
    {
        field: 'doctor_username', 
        headerName: 'Врач ТМЦ', 
        width: 120,
    },
]


export default patient_history_columns