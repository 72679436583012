import { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import './ag-multiselect.css'


export default function AgMultiSelect(props){
    const [ choices, setChoices ] = useState(props.choices)
    const [ select_value, setSelectValue ] = useState(props.value)
    useEffect(() => {
        setChoices(props.choices)
    }, [props.choices])
    useEffect(() => {
        if (props.value){
            let select_value = []
            for (let i=0; i<props.value.length; i++){
                select_value.push(props.value[i].name)
            }
            setSelectValue(select_value)
        }
    }, [props.value])
    
    return (
        choices !== undefined && select_value !== undefined
        ?
        <div>
            <Select
            sx={{ maxWidth: 260 }}
            key={1}
            error={props.error}
            size="small"
            // id="pregnant-multiselect"
            multiple
            value={select_value || []}
            onChange={(e) => {
                props.onChange(e.target.value)
            }}   
            style={{ width: "100%" }}
            >
            {
                choices.map((obj) => {
                    return <MenuItem value={obj.name}>{obj.name}</MenuItem>
                })  
            }
            </Select>
        </div>
        :
        <div>
            <Select
            key={2}
            displayEmpty
            size="small"
            error={props.error}
            multiple
            value={select_value || []}
            onChange={(e) => {
                props.onChange(e.target.value)
            }}   
            style={{ width: "100%" }}
            >
            </Select>
        </div>
    )
}