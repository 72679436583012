import { TextField as TextFieldBase, Typography, Grid, Dialog, Button, accordionActionsClasses, IconButton, autocompleteClasses } from "@mui/material"
import { useEffect, useState } from "react"
import patient_fields from "./patient-fields"
import getPatientFields from "arterial_hypertension/api/patient/get-patient-fields"
import AgDatepicker from "arterial_hypertension/elements/ag-datepicker"
import AgMultiSelect from "arterial_hypertension/elements/ag_multiselect/ag-multiselect"
import AgMultiselectValuesPlaceholder from "arterial_hypertension/elements/ag_multiselect/ag-multiselect-values-placeholder"
import AgHistoryTable from "arterial_hypertension/elements/ag-history-table"
import AgSelect from "arterial_hypertension/elements/ag_select/ag-select"
import getPatient from "arterial_hypertension/api/patient/get-patient"
import AgPhones from "arterial_hypertension/elements/ag-phones"
import replaceMultipleSpacesToOne from "arterial_hypertension/custom-methods/replace-multiple-spaces-to-one"
import isOnlyNumbersIncluded from "arterial_hypertension/custom-methods/is-only-numbers-included"
import isSeventhCharIsZero from "arterial_hypertension/custom-methods/is-seventh-chat-is-zero"
import parseGenderByIinNumber from "arterial_hypertension/custom-methods/parse-gender-by-iin-number"
import parseYearByIinNumber from "arterial_hypertension/custom-methods/parse-year-by-iin-number"
import parseMonthFromIin from "arterial_hypertension/custom-methods/parse-month-from-iin"
import parseDayFromIin from "arterial_hypertension/custom-methods/parse-day-from-iin"
import report_fields_obj from "./report-fields"
import postMonitoringCreate from "arterial_hypertension/api/monitoring/post-monitoring-create"
import getReportCreate from "arterial_hypertension/api/report/post-report-create"
import postReportCreate from "arterial_hypertension/api/report/post-report-create"
import replaceEmptyParametersOfDataToNull from "arterial_hypertension/custom-methods/replace-empty-parameters-of-data-to-null"
import isAllParametersOfObjectIsNull from "arterial_hypertension/custom-methods/is-all-parameters-of-object-is-null"
import removeItemByIndex from "arterial_hypertension/custom-methods/remove-item-by-index"
import PhoneAdd from "../phone/phone-add"
import PhoneEdit from "../phone/phone-edit"
import AddIcon from '@mui/icons-material/Add'
import patient_history_columns from "./patient-history-columns"
import getReportHistory from "arterial_hypertension/api/report/get-report-history"
import getReportFields from "arterial_hypertension/api/report/get-report-fields"



const border_styles = {
    borderRadius: '20px',
    border: "1px solid #ced4da",
}

const border_styles_with_max_width = {
    borderRadius: '20px',
    border: "1px solid #ced4da",
    maxWidth: '49.3%',
}

const block_title_styles = {
    fontWeight: 'bold',
    fontSize: '30px',
}

const info_title_styles = {
    fontWeight: 'bold',
    fontSize: '23px',
}
const input_label_styles = {
    fontWeight: 'bold',
    fontSize: '15.3px',
}

const patient_modal_styles = {
    position: 'absolute',
    backgroundColor: 'white',
    border: '1px solid rgb(206, 212, 218)',
    borderRadius: '1em',
    zIndex: '1000',
    maxWidth: '90vw', 
    maxHeight: '90vh',
    overflow: 'auto',
    marginTop: '3vh', 
    // marginLeft: '1vw',
}

const monitoring_dialog_styles = { 
    maxWidth: '95vw',
    maxHeight: '100vh',
    height: '90vh',
    marginTop: '4vh',
    marginLeft: '3vw',
}


const initial_validation_object = {
    btn_text: 'Сохранить',
    message: '',
}


const TextField = (props) => {
    const is_full_width = props.fullWidth === false ? false : true
    return (
        <TextFieldBase
        disabled={props.disabled}
        value={props.value || ''}
        fullWidth={is_full_width}
        onBlur={() => {try{props.onBlur(props.id)}catch(err){}} }
        helperText={props.helperText}
        size='small'
        onChange={(event) => {props.onChange(event.target.value)}}
        />
    )
}


export default function Patient(props){
    const [data, setData] = useState(undefined)
    const [fields, setFields] = useState(patient_fields)
    const [report_fields, setReportFields] = useState(report_fields_obj)
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState(patient_history_columns)
    const [validation, setValidation] = useState(initial_validation_object)
    
    
    const [comment, setComment] = useState('')
    const [accompanying_illness, setAccompanyingIllness] = useState([])
    const [date_of_registration_by_tmc, setDateOfRegistrationByTmc] = useState('')
    const [degree_of_arterial_hypertension, setDegreeOfArterialHypertension] = useState(null)
    const [degree_of_risk, setDegreeOfRisk] = useState(null)
    const [diagnosis, setDiagnosis] = useState(null)
    const [drugs, setDrugs] = useState([])
    const [full_name, setFullName] = useState('')
    const [phone_add_modal_status, setPhoneAddModalStatus] = useState({
        index: undefined,
        is_open: false,
    })
    const [phone_edit_modal_status, setPhoneEditModalStatus] = useState({
        is_open: false,
    })
    const [gender, setGender] = useState(null)
    const [iin, setIin] = useState('')
    const [date_of_birth, setDateOfBirth] = useState('')
    const [phone, setPhone] = useState([])
    const [pmsp, setPmsp] = useState(null)
    const [pmsp_area, setPmspArea] = useState(null)
    const [residential_address, setResidentialAddress] = useState('')
    const [risk_factor, setRiskFactor] = useState([])
    
    const [health_complaints, setHealthComplaints] = useState([])
    const [comment_on_health_complaints, setCommentOnHealthComplaints] = useState('')
    const [operator_request_status, setOperatorRequestStatus] = useState([])
    const [taking_medications, setTakingMedications] = useState([])
    const [medical_procedures, setMedicalProcedures] = useState([])
    const [complaints_and_suggestions_about_pmsp_work , setComplaintsAndSuggestionsAboutPmspWork] = useState([])
    const [comment_on_complaints_and_suggestions_about_pmsp_work, setCommentOnComplaintsAndSuggestionsAboutPmspWork] = useState('')
    const [date_of_next_call, setDateOfNextCall] = useState('')
    
    const [phone_modal_key, setPhoneModalKey] = useState(1)
    
    const [comment_validation_message, setCommentValidationMessage] = useState('')
    const [accompanying_illness_validation_message, setAccompanyingIllnessValidationMessage] = useState('')
    const [date_of_registration_by_tmc_validation_message, setDateOfRegistrationByTmcValidationMessage] = useState('')
    const [degree_of_arterial_hypertension_validation_message, setDegreeOfArterialHypertensionValidationMessage] = useState('')
    const [degree_of_risk_validation_message, setDegreeOfRiskValidationMessage] = useState('')
    const [diagnosis_validation_message, setDiagnosisValidationMessage] = useState('')
    const [drugs_validation_message, setDrugsValidationMessage] = useState('')
    const [full_name_validation_message, setFullNameValidationMessage] = useState('')
    const [gender_validation_message, setGenderValidationMessage] = useState('')
    const [iin_validation_message, setIinValidationMessage] = useState('')
    const [date_of_birth_validation_message, setDateOfBirthValidationMessage] = useState('')
    const [phone_validation_message, setPhoneValidationMessage] = useState('')
    const [pmsp_validation_message, setPmspValidationMessage] = useState('')
    const [pmsp_area_validation_message, setPmspAreaValidationMessage] = useState('')
    const [residential_address_validation_message, setResidentialAddressValidationMessage] = useState('')
    const [risk_factor_validation_message, setRiskFactorValidationMessage] = useState('')
    
      
    const convertToNameStr = (arr) => {
        let res_array = []
        for (let i = 0; i < arr.length; i++){
            res_array.push({name: arr[i]})
        }
        return res_array
    }
    const setFioWithValidation = (val) => {
        if (val.length > 75){
            return 0
        }
        val = replaceMultipleSpacesToOne(val)
        if (/^[А-яа-яA-za-z-ӘәЁёҚқІіҒғҰұӨөҮүҺһҢң\s]*$/.test(val)){
            setFullName(val)
        }
    } 
    const setIinWithValidation = (val) => {
        if (val.length > 12){
            return 0
        }
        if (isOnlyNumbersIncluded(val)){
            setIin(val)
        }
    }
    const handleIinField = () => {
        if (iin.length < 12){
            return setIinValidationMessage('ИИН не может быть менее 12 символов')
        }
    }
    const setRiskFactorMultiple = (val) => {
        let risk_factor = []
        val.map((el) => {
            risk_factor.push({name: el})
        })
        setRiskFactor(risk_factor)
    }
    const setOperatorRequestStatusMultiple = (val) => {
        let operator_request_status = []
        for (let i=0; i<val.length; i++){
            operator_request_status.push({name: val[i]})
        }
        setOperatorRequestStatus(operator_request_status)
    }
    const setComplaintsAndSuggestionsAboutPmspWorkMultiple = (val) => {
        let complaints_and_suggestions_about_pmsp_work = []
        for (let i=0; i<val.length; i++){
            complaints_and_suggestions_about_pmsp_work.push({name: val[i]})
        }
        setComplaintsAndSuggestionsAboutPmspWork(complaints_and_suggestions_about_pmsp_work)
    }
    const setHealthComplaintsMultiple = (val) => {
        let health_complaints = []
        for (let i=0; i<val.length; i++){
            health_complaints.push({name: val[i]})
        }
        console.log('health_complaints', health_complaints)
        setHealthComplaints(health_complaints)
    }
    const setTakingMedicationsMultiple = (val) => {
        let taking_medications = []
        for (let i=0; i<val.length; i++){
            taking_medications.push({name: val[i]})
        }
        setTakingMedications(taking_medications)
    }
    const setAccompanyingIllnessMultiple = (val) => {
        let accompanying_illness = []
        for (let i=0; i<val.length; i++){
            accompanying_illness.push({name: val[i]})
        }
        console.log('accompanying_illness', accompanying_illness)
        setAccompanyingIllness(accompanying_illness)
    }
    const setDrugsMultiple = (val) => {
        let drugs = []
        for (let i=0; i<val.length; i++){
            drugs.push({name: val[i]})
        }
        console.log('drugs', drugs)
        setDrugs(drugs)
    }
    const handleIinFieldForGender = () => {
        setGender({name: parseGenderByIinNumber(iin)})
    }
    const handleIinFieldForDateOfBirth = () => {
        const year = parseYearByIinNumber(iin)
        const month = parseMonthFromIin(iin)
        const day = parseDayFromIin(iin)
        setDateOfBirth(year + '-' + month + '-' + day)
    } 
    const handleFioField = () => {
        if (full_name.length < 3){
            setFullNameValidationMessage('В поле ФИО должно быть не менее 3-х символов')
        }
        else{
            setFullNameValidationMessage('')
        }
    }
    const handleGetReportHistory = (patient_id) => {
        getReportHistory(patient_id)
        .then((res) => {
            if (res.data){
                let rows = res.data
                for (let i=0; i<rows.length; i++){
                    rows[i]['id'] = i
                }
                setRows(rows)
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }
    
    const handleGetFields = () => {
        getPatientFields()
        .then((res) => {
            if (res.data){
                setFields(res.data)
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }   
    const handleGetPatient = (patient_id) => {
        getPatient(patient_id)
        .then((res) => {
            if (res.data){
                setData(res.data)
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const handleGetReportFields = () => {
        getReportFields()
        .then((res) => {
            if (res.data){
                setReportFields(res.data)
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const checkIfDataIsEmpty = () => {
    }
    const handlePostReportCreate = () => {
        // let report_fields = {
        //     health_complaints: health_complaints,
        //     comment_on_health_complaints: comment_on_health_complaints,
        //     operator_request_status: operator_request_status,
        //     taking_medications: taking_medications,
        //     medical_procedures: medical_procedures,
        //     complaints_and_suggestions_about_pmsp_work: complaints_and_suggestions_about_pmsp_work,
        //     comment_on_complaints_and_suggestions_about_pmsp_work: comment_on_complaints_and_suggestions_about_pmsp_work,
        // }
        // let checked_report_fields = replaceEmptyParametersOfDataToNull(report_fields)
        // if (isAllParametersOfObjectIsNull(checked_report_fields)){
        //     return setValidation({
        //         message: 'Заполните хотя бы одно поле в жалобах или в замечаниях',
        //         btn_text: 'Ошибка',
        //     })
        // }
        let data = {
            patient_id: props.patient_id,
            full_name: full_name,
            iin: iin,
            date_of_birth: date_of_birth,
            phone: phone,
            gender: gender,
            residential_address: residential_address,
            diagnosis: diagnosis,
            degree_of_arterial_hypertension: degree_of_arterial_hypertension,
            risk_factors: risk_factor,
            degree_of_risk: degree_of_risk,
            accompanying_illnesses: accompanying_illness,
            pmsp: pmsp,
            pmsp_area: pmsp_area,
            drugs: drugs,
            comment: comment,
            health_complaints: health_complaints,
            taking_medications: taking_medications,
            operator_request_status: operator_request_status,
            medical_procedures: medical_procedures,
            comment_on_health_complaints: comment_on_health_complaints,
            complaints_and_suggestions_about_pmsp_work: complaints_and_suggestions_about_pmsp_work,
            comment_on_complaints_and_suggestions_about_pmsp_work: comment_on_complaints_and_suggestions_about_pmsp_work,
            date_of_next_call: date_of_next_call,
            doctor_username: localStorage.getItem("username"),
        }
        if (!degree_of_risk?.name){
            return setDegreeOfRiskValidationMessage('Выберите степень риска')
        }
        let push_data = replaceEmptyParametersOfDataToNull(data)
        postReportCreate(push_data)
        .then((res) => {
            if (res.data){
                props.handleClosePatient()
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const toggleAddPhoneModal = () => {
        setPhoneAddModalStatus({
            is_open: !phone_add_modal_status.is_open,
        })
    }
    const toggleEditPhoneModal = (index) => {
        setPhoneEditModalStatus({
            is_open: !phone_edit_modal_status.is_open,
            index: index,
        })
    }
    const addPhone = (phones) => {
        setPhone([])
        setTimeout(() => {
            setPhone(phones)
        }, 200);
    }
    const editPhone = (edit_phone, index) => {
        const phones_with_current = phone
        console.log('phones_with_current ', phones_with_current)
        const phones_without_current = removeItemByIndex(phones_with_current, index)
        console.log('phones_without_current ', phones_without_current)
        if (phones_without_current.map((el) => el.phone).includes(edit_phone.phone)){
            setPhoneModalKey(phone_modal_key + 1)
            return props.notify('error', 'Нельзя изменить номер телефона так, чтобы он совпадал со существующим')
        }
        else{
            console.log('phones_without_current[index=edit_phone] BEFORE', phones_without_current)
            phones_without_current[index] = edit_phone
            console.log('phones_without_current[index=edit_phone] AFTER', phones_without_current)
            // phones_without_current.push(edit_phone)
            setPhone([])
            setTimeout(() => {
                setPhone(phones_without_current)
            }, 200);
        }

    }
    const removePhone = (phones) => {
        setPhone([])
        setTimeout(() => {
            setPhone(phones)
        }, 200);
    }
     const handlePhonesChanged = (subject) => {
        let helper_text = ''
        if (subject === 'pregnant'){
          let phone_list = this.state.pregnant.phone
          if (phone_list.length === 0){
            helper_text = "Должен быть хотя бы один номер телефона"
          }
          return this.setState({validation: {
            ...this.state.validation, pregnant_phones_helper_text: helper_text,
          }})
        }
        else if (subject === 'father'){
          let phone_list = this.state.info_about_pregnant_at_current_pregnancy.father_info
          if (phone_list.length === 0){
            helper_text = "Должен быть хотя бы один номер телефона"
          }
          return this.setState({validation: {
            ...this.state.validation, father_phones_helper_text: helper_text,
          }})
        }
    
    }
    useEffect(() => {
        if (props.patient_id && !isNaN(parseInt(props.patient_id))){
            handleGetPatient(props.patient_id)
            handleGetReportHistory(props.patient_id)
        }
    }, [props.patient_id])
    
    useEffect(() => {
        if (props.toggled){
            handleGetFields()
            handleGetReportFields()
        }
    }, [props.toggled]) 
    useEffect(() => {
        if (iin.length === 12 && !iin_validation_message && !isSeventhCharIsZero(iin)){
            if (!data?.gender){
                handleIinFieldForGender()
            }
            if (!data?.date_of_birth){
                handleIinFieldForDateOfBirth()
            }
        }
    }, [iin])  
        
    useEffect(() => {
        if (data){
            setAccompanyingIllness(data?.accompanying_illness)
            setDateOfRegistrationByTmc(data?.date_of_registration_by_tmc)
            setDegreeOfArterialHypertension(data?.degree_of_arterial_hypertension)
            setDegreeOfRisk(data?.degree_of_risk)
            setDiagnosis(data?.diagnosis)
            setDrugs(data?.drugs)
            setFullName(data?.full_name)
            setGender(data?.gender)
            setPhone(data?.phone)
            setPmsp(data?.pmsp)
            setPmspArea(data?.pmsp_area)
            setRiskFactor(data?.risk_factor)
            setDateOfBirth(data?.date_of_birth)
            setResidentialAddress(data?.residential_address)
            setIin(data?.iin)
            setDateOfNextCall(data?.date_of_next_call)
            setComment(data?.comment)
        }
    }, [data])
    
    
    useEffect(() => {
        console.log('gender now will be', gender)
    }, [gender])
    
    if (!props.toggled){
        return <></>
    }
    return (
        <Dialog
        onClose={props.handleClosePatient} 
        fullScreen open={props.toggled}
        style={monitoring_dialog_styles}
        >
            <Grid container p={3}>
                <Grid xs={12} md={12} lg={12} item container justifyContent='space-between' p={3} pt={0}>
                    <Grid xs={6} md={6} lg={6} item>
                        <Typography style={block_title_styles}>Заполнить информацию о пациенте</Typography>
                    </Grid>
                    <Grid textAlign='end' xs={6} md={6} lg={6} item>
                        <Typography style={{ cursor: 'pointer' }} onClick={() => props.handleClosePatient()}>X</Typography>
                    </Grid>
                </Grid>
                <Grid xs={12} md={12} lg={12} item container style={border_styles} p={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography style={info_title_styles}>
                            Личные данные
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} container spacing={2}>
                        <Grid item xs={2} lg={2} md={2}>
                            <Typography style={input_label_styles}>ФИО</Typography>
                            <TextField 
                            value={full_name}
                            helperText={full_name_validation_message}
                            onChange={setFioWithValidation}
                            onBlur={handleFioField} 
                            />
                        </Grid>
                        <Grid item xs={2} lg={2} md={2}>
                            <Typography style={input_label_styles}>ИИН</Typography>
                            <TextField 
                            value={iin}
                            onChange={setIinWithValidation}
                            onBlur={handleIinField}
                            helperText={iin_validation_message} 
                            />
                        </Grid>
                        <Grid item xs={2} lg={2} md={2}>
                            <Typography style={input_label_styles}>Дата рождения</Typography>
                            <AgDatepicker
                            onChange={setDateOfBirth}
                            value={date_of_birth} 
                            />
                        </Grid>
                        <Grid item xs={1} lg={1} md={1}>
                            <Typography style={input_label_styles}>Пол</Typography>
                            <AgSelect
                            onChange={setGender}
                            choices={fields?.gender}
                            value={gender}
                            />
                        </Grid>
                        <Grid item xs={2} lg={2} md={2}>
                            <Grid container>
                                <Grid item>
                                    <Typography style={input_label_styles}>Номер телефона</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                    size='small'
                                    children={<AddIcon style={{ fontSize: '1.15em' }} />}
                                    onClick={() => toggleAddPhoneModal()}
                                    style={{
                                      textTransform: 'none',
                                      backgroundColor: '#5280FF',
                                      color: 'white',
                                      fontWeight: 'bolder',
                                      height: '1.2em',
                                      width: '1.2em',
                                      borderRadius: '25%',
                                      border: '2px solid #c5d7fb',
                                    }}
                                    variant='contained'
                                    />
                                </Grid>
                            </Grid>
                            <AgPhones
                            choices={phone}
                            notify={props.notify}
                            toggleEditPhoneModal={toggleEditPhoneModal}
                            removePhone={removePhone}
                            />
                            <PhoneAdd
                            id="phone_modal"
                            notify={props.notify}
                            toggled={phone_add_modal_status.is_open}
                            toggleAddPhoneModal={toggleAddPhoneModal}
                            addPhone={addPhone}
                            phones={phone} 
                            />
                            <PhoneEdit
                            id="phone_modal"
                            key={phone_modal_key}
                            notify={props.notify}
                            edit_index={phone_edit_modal_status.index}
                            toggled={phone_edit_modal_status.is_open}
                            toggleEditPhoneModal={toggleEditPhoneModal}
                            editPhone={editPhone}
                            phones={phone} 
                            />
                        </Grid>
                        <Grid item xs={2} lg={2} md={2}>
                            <Typography style={input_label_styles}>Адрес фактического проживания</Typography>
                            <TextField 
                            value={residential_address} 
                            onChange={setResidentialAddress}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} container spacing={2}>
                        <Grid item xs={2} lg={2} md={2}>
                            <Typography style={input_label_styles}>Диагноз</Typography>
                            <AgSelect
                            onChange={setDiagnosis}
                            value={diagnosis} 
                            choices={fields?.diagnosis}
                            />
                        </Grid>
                        <Grid item xs={2} lg={2} md={2}>
                            <Typography style={input_label_styles}>Степень АГ</Typography>
                            <AgSelect
                            onChange={setDegreeOfArterialHypertension}
                            value={degree_of_arterial_hypertension}
                            choices={fields?.degree_of_arterial_hypertension}
                            />
                        </Grid>
                        <Grid item xs={2} lg={2} md={2}>
                            <Typography style={input_label_styles}>Факторы риска</Typography>
                            <AgMultiSelect
                            onChange={setRiskFactorMultiple}
                            value={risk_factor}
                            choices={fields?.risk_factor}
                            />
                        </Grid>
                        <Grid item xs={2} lg={2} md={2}>
                            <Typography style={input_label_styles}>Степень риска</Typography>
                            <AgSelect
                            onChange={setDegreeOfRisk}
                            error={Boolean(degree_of_risk_validation_message)}
                            helperText={degree_of_risk_validation_message}
                            value={degree_of_risk}
                            choices={fields?.degree_of_risk}
                            />
                        </Grid>
                        <Grid item xs={2} lg={2} md={2}>
                            <Typography style={input_label_styles}>Сопутствующие заболевания</Typography>
                            <AgMultiSelect
                            onChange={setAccompanyingIllnessMultiple}
                            value={accompanying_illness}
                            choices={fields?.accompanying_illness}
                            />
                        </Grid>
                        <Grid item xs={2} lg={2} md={2}>
                            <Typography style={input_label_styles}>ПМСП</Typography>
                            <Typography>{pmsp?.name}</Typography>
                        </Grid>
                        <Grid item xs={1} lg={1} md={1}>
                            <Typography style={input_label_styles}>Участок</Typography>
                            <Typography>{pmsp_area?.name}</Typography>
                        </Grid>
                        <Grid item xs={2} lg={2} md={2}>
                            <Typography style={input_label_styles}>Препараты выданные по ГОБМП</Typography>
                            <AgMultiSelect
                            onChange={setDrugsMultiple}
                            value={drugs}
                            choices={fields?.drugs}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} container spacing={2}>
                        <Grid xs={7} lg={7} md={7} item>
                            <Typography style={input_label_styles}>Комментарий</Typography>
                            <TextField
                            onChange={setComment}
                            fullWidth={true}
                            value={comment}
                            />
                        </Grid>
                        <Grid xs={5} lg={5} md={5} item>
                            <Typography style={input_label_styles}>Дата следующего звонка</Typography>
                            <AgDatepicker value={date_of_next_call} onChange={setDateOfNextCall}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12} container spacing={2} justifyContent='space-between' mt={4} pl={2}>
                    <Grid item xs={6} md={6} lg={6} style={border_styles_with_max_width} p={3} container>
                        <Typography style={info_title_styles}>Жалобы по здоровью</Typography>
                        <Grid xs={12} md={12} lg={12} item container spacing={6}>
                            <Grid xs={6} md={6} lg={6} item>
                                <Typography style={input_label_styles}>Категория жалобы по здоровью</Typography>
                                <AgMultiSelect
                                choices={report_fields?.health_complaints} 
                                value={health_complaints} 
                                onChange={setHealthComplaintsMultiple} 
                                />
                            </Grid>
                            <Grid xs={6} md={6} lg={6} item>
                                <Typography style={input_label_styles}>Оператор (статус обращения)</Typography>
                                <AgSelect
                                choices={report_fields?.operator_request_status} 
                                value={operator_request_status} 
                                onChange={setOperatorRequestStatus} 
                                />
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={12} lg={12} item container spacing={6}>
                            <Grid xs={6} md={6} lg={6} item>
                                <Typography style={input_label_styles}>Приём препаратов</Typography>
                                <AgMultiSelect
                                id='taking_drugs'
                                value={taking_medications}
                                choices={report_fields.taking_medications}
                                onChange={setTakingMedicationsMultiple} 
                                fullWidth={true} />
                            </Grid>
                            <Grid xs={6} md={6} lg={6} item>
                                <Typography style={input_label_styles}>Врачебные мероприятия</Typography>
                                <AgSelect
                                id='description_of_health_complaints'
                                value={medical_procedures}
                                choices={report_fields.medical_procedures}
                                onChange={setMedicalProcedures} 
                                fullWidth={true} />
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={12} lg={12} item>
                            <Typography style={input_label_styles}>Комментарий</Typography>
                            <TextField
                            key={99}
                            id='health_complaints_comment'
                            value={comment_on_health_complaints}
                            onChange={setCommentOnHealthComplaints} 
                            fullWidth={true} />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} style={border_styles_with_max_width} p={3} container>
                        <Typography style={info_title_styles}>Замечания или пожелания по работе ПМСП</Typography>
                        <Grid xs={12} md={12} lg={12} item>
                            <Typography style={input_label_styles}>Категория замечаний или пожеланий по работе ПМСП</Typography>
                            <AgMultiSelect
                            id='complaints_and_suggestions_about_pmsp_work'
                            choices={report_fields?.complaints_and_suggestions_about_pmsp_work}
                            value={complaints_and_suggestions_about_pmsp_work}
                            onChange={setComplaintsAndSuggestionsAboutPmspWorkMultiple} 
                            />
                        </Grid>
                        <Grid xs={12} md={12} lg={12} item>
                            <Typography style={input_label_styles}>Комментарий</Typography>
                            <TextField
                            id='complaints_and_suggestions_about_pmsp_work_comment'
                            value={comment_on_complaints_and_suggestions_about_pmsp_work}
                            onChange={setCommentOnComplaintsAndSuggestionsAboutPmspWork}
                            multiline
                            rows={4}
                            fullWidth={true} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12} md={12} textAlign='end' mt={2}>
                    <Typography style={{color: 'red', fontWeight: 'bold', fontSize: '20px'}}>
                        {validation.message}
                    </Typography>
                    <Button
                    onClick={() => handlePostReportCreate()}
                    size='small'
                    style={{ fontSize: '16px', fontWeight: 'bold', width: '15%', borderRadius: '6.7px' }}
                    color='primary'
                    variant='contained'
                    >{validation.btn_text}</Button>
                    <Typography style={{ color: 'red' }}>{validation.info}</Typography>
                </Grid>
                <Grid item xs={12} lg={12} md={12} container>
                    <Grid pl={3} xs={12} md={12} lg={12} item>
                        <Typography style={block_title_styles}>История</Typography>
                    </Grid>
                    <Grid xs={12} md={12} lg={12} item>
                        <AgHistoryTable columns={columns} rows={rows} />
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}