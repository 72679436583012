import { useState, useEffect } from 'react'
import AgResultsTable from '../../elements/ag-results-table-desktop';
import AgSearchField from '../../elements/ag-text-field';
import getPatientSearch from 'arterial_hypertension/api/patient/get-patient-search';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Grid, Button } from '@mui/material';
import AgChosePhone from '../../elements/ag-chose-phone';
import Patient from '../../components/patient/patient';
import Monitoring from '../../components/monitoring/monitoring';
import brightpattern_config from 'brightpattern-config';
import Widget from '../../components/widget/widget';
import ag_results_table_columns from './ag-results-table-columns';
import patient_fields from 'arterial_hypertension/components/patient/patient-fields';
import monitoring_fields from 'arterial_hypertension/components/monitoring/monitoring-fields';
import Notification from 'notification/notification';
import postCallHistoryCreate from 'arterial_hypertension/api/call-history/post-call-history-create';
import videoCallUsers from 'video-call-users'


const BASE_URL =  process.env.REACT_APP_BASE_URL
const videoCallUsers_ = videoCallUsers.users


const modal_block_styles = {
    borderRadius: '20px',
    border: "1px solid #ced4da",
    marginLeft: '1.4%',
    marginRight: '1.4%',
    padding: '2%',
}


export default function PatientInfo({ iin }){
    const [query, setQuery] = useState('')
    const [rows, setRows] = useState([])
    const [data, setData] = useState({})
    const [cols, setCols] = useState(ag_results_table_columns)
    const [phones, setPhones] = useState([])
    const [patient_id, setPatientId] = useState(null)
    const [selected_phone, setSelectedPhone] = useState('')
    const [searchTimeout, setSearchTimeout] = useState(null)
    const [columns, setColumns] = useState([])
    const [is_patient_modal_open, setIsPatientModalOpen] = useState(false)
    const [patient_modal_key, setPatientModalKey] = useState(0)
    const [_patient_fields, setPatientFields] = useState(patient_fields)
    const [is_monitoring_modal_open, setIsMonitoringModalOpen] = useState(false)
    const [monitoring_modal_key, setMonitoringModalKey] = useState(0)
    const [_monitoring_fields, setMonitoringFields] = useState(monitoring_fields)
    const [call_button_disabled, setCallButtonState] = useState(false)
    const [chose_phone, setChosePhone] = useState('')
    const [page_loaded, setPageLoaded] = useState(false)
    const [call_data, setCallData] = useState(undefined)
    const [got_message, setGotMessage] = useState(false)
    const [notification, setNotification] = useState({
        open: false,
        text: '',
        type: '',
    })
    
    const handleSetGotMessage = (val) => {
        if (val !== got_message){
            setGotMessage(val)
        }
    }
    const returnCallData = (call_back_data) => {
        // call_back_data.data["date_of_call"] =  new Date().toLocaleDateString('en-CA') + "T" + new Date().toLocaleTimeString('it-IT')
        // call_back_data.data["doctor_username"] = localStorage.getItem("username")
        // call_back_data.data["patient_id"] = data?.patient_id
        // call_back_data.data["phone"] = '+7' + call_back_data.data?.phone_number?.substring(1)
        // call_back_data.data["type_of_call"] = {name: "аудио"}
        // call_back_data.data["status_of_call"] = {name: call_back_data.data.disposition}
        // call_back_data.data["recording_url"] = "recording_url"
        let ready_data = {
            global_id: call_back_data.global_id,
            item_id: call_back_data.item_id,
            date_of_call: new Date().toLocaleDateString('en-CA') + "T" + new Date().toLocaleTimeString('it-IT'),
            doctor_username: localStorage.getItem("username"),
            patient_id: data?.patient_id,
            phone: '+7' + call_back_data?.phone_number?.substring(1),
            type_of_call: {name: "аудио"},
            status_of_call: {name: call_back_data.disposition},
            recording_url: "recording_url",
        }
        console.log('ready_data in returnCallData', ready_data)
        return ready_data
    }
    
    const handleSetCallData = (call_back_data) => {
        localStorage.setItem("global_id", call_back_data.data.global_id)
        let ready_data = returnCallData(call_back_data.data)
        console.log('ready_data in handleSetCallData', ready_data)
        let ready_data_keys = Object.keys(ready_data)
        if (!ready_data_keys.includes('patient_id')){
            ready_data_keys['patient_id'] = data?.patient_id
        }
        setCallData(ready_data)
        // createCallHistory(data.data)
    }
    const handleCallDataChanged = (data) => {
        postCallHistoryCreate(data)
        .then((res) => {
            if (res.data){
                notify("success", "Запись о звонке успешно добавлена")
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const initBrightPatternInterface = () => {
        let url = process.env.NODE_ENV === 'development' 
        ? brightpattern_config.prod_url 
        :  brightpattern_config.url
        window.bpspat.api.init(url)
    }
    const initBrightPatternCall = (phone) => {
        console.log('initBrightPatternCall triggered', phone)
        if (!phone){
            notify("error", "Сначала нужно выбрать номер!")
            return 0
        }
        let service = process.env.NODE_ENV === 'development' 
        ? brightpattern_config.prod_service
        :  brightpattern_config.service
        window.bpspat.api.selectService(service)
        // setTimeout(() => {
        window.bpspat.api.dialNumber(phone.replace("+7", "7"))
        // }, 200);
    }
    useEffect(() => {
        console.log('data changed ', data)
        if (data){
            if (JSON.stringify(data) !== '{}'){
                let rows = []
                rows.push(data)
                setPatientId(data?.patient_id)
                setChosePhone('')
                setRows(rows)
            }
        }
    }, [data])
    
    // #TO UNCOMMENT
    // useEffect(() => {
    //     if (!isNaN(parseInt(patient_id))){
    //         window.addEventListener("message", BrightPatternMessageEventHandler)
    //     }
    // }, [patient_id])
    
    useEffect(() => {
        setPatientId(data?.patient_id)
    }, [is_patient_modal_open])
    
    useEffect(() => {
        setPatientId(data?.patient_id)
    }, [is_monitoring_modal_open])
    
    const chosePhone = (val) => {
        setChosePhone(val)
    }
    const setIframeActive = () => {
        if (!page_loaded){
            setPageLoaded(true)
        }
    }
    const togglePatientModalOpen = () => {
        setIsPatientModalOpen(!is_patient_modal_open)
    }
    const toggleMonitoringModalOpen = () => {
        setIsMonitoringModalOpen(!is_monitoring_modal_open)
    }
    const setQueryChange = (query) => {
        setQuery(query)
    }
    const handleQueryChange = (query) => {
        getRowsData(query)
    }
    const replacePlusWithEncodedOne = (val) => {
        return val.includes('+') ? val.replace('+', '%2B') : val
    }
    const getRowsData = (search) => {
        search = replacePlusWithEncodedOne(search)
        clearTimeout(searchTimeout)
        setSearchTimeout(setTimeout(() => {
            getPatientSearch(search)
            .then((res) => {
              if (res.data) {
                setData(res.data)
                setRows([])
              }
            })
            .catch((err) => {
                setData({})
                setRows([])
            })
        }, 1000))
    }
    const handleSetSelectedPhone = (phone) => {
        chosePhone(phone)
    }
    const initZoomVideoCall = (callTarget) => {
      const phone = callTarget.replace('+', '')
      if (!phone) {
        return this.props.notify('error', 'Выберите номер телефона')
      }
      window.open(`${BASE_URL}/zoom?call_inited=true&phone=${phone}`, '_blank')
    }
    useEffect(() => {
        if (call_data){
            handleCallDataChanged(call_data)
            console.log('handleCallDataChanged(call_data)')
        }
    }, [call_data])
    
    useEffect(() => {
        if (is_patient_modal_open){
        }
        else{
            setPatientModalKey(patient_modal_key + 1)
        }
    }, [is_patient_modal_open])
    
    useEffect(() => {
        if (is_monitoring_modal_open){
        }
        else{
            setMonitoringModalKey(monitoring_modal_key + 1)
        }
    }, [is_monitoring_modal_open])
    
    useEffect(() => {
        setQuery(iin)
        handleQueryChange(query)

        // if (query){
        // }
        // else if (query === ''){
        //     setData({})
        //     setRows([])
        //     setPatientId(null)
        // }
    }, [query])
    
    useEffect(() => {
        if (rows.length > 0){
            setPhones(rows[0]?.phone)
        }
    }, [rows])
    const notify = (type, message, close) => {
        if (close){
          setNotification({
              open: false,
              text: notification.text,
              type: notification.type,
            })
        }
        else{
          setNotification({
            open: true,
            text: message,
            type: type,
          })
        }
      }

    return (
        <Grid container gap={2} justifyContent='center'>
            {/* <Grid textAlign="center" item lg={10} xs={10} md={10} mt={4}>
                <AgSearchField
                value={query}
                onChange={setQueryChange}
                InputProps={{
                    inputProps: {
                        style: { textAlign: "center" },
                    }
                }}
                iconStart={ <SearchOutlinedIcon/> }></AgSearchField>
            </Grid> */}
            <Grid textAlign="center" item lg={10} xs={10} md={10}>
                <AgResultsTable rows={rows} columns={cols} />
            </Grid>
            {
                rows.length > 0 &&
                <Grid item container lg={8} xs={8} md={8} style={modal_block_styles}>
                    <Grid lg={8} xs={8} md={8} item container direction='column' spacing={1}>
                        <Grid item>
                            <Button
                            key={1}
                            variant='contained'
                            style={{
                                backgroundColor: 'orange',
                                width: '75%',
                                fontSize: '1.3em',
                                padding: '2.2%',
                                textTransform: 'none',
                                fontWeight: '500',
                                // maxHeight: '6vh',
                            }}
                            onClick={() => togglePatientModalOpen()}
                            >
                            Заполнить информацию о пациенте
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                            key={2}
                            variant='contained'
                            style={{
                                backgroundColor: '#DC143C',
                                width: '75%',
                                fontSize: '1.3em',
                                padding: '2.2%',
                                textTransform: 'none',
                                fontWeight: '500',
                                marginBottom: '2.3vh',
                            }}
                            onClick={() => toggleMonitoringModalOpen()}
                            >
                            Мониторинг
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid lg={4} xs={4} md={4} item container flexDirection='column' spacing={2}>
                        <Grid item>
                            <AgChosePhone
                            choices={phones}
                            onChange={handleSetSelectedPhone}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                            onClick={() => initBrightPatternCall(chose_phone)}
                            variant="contained"
                            fullWidth
                            color="primary"
                            disabled={call_button_disabled}
                            style={{
                                width: '100%',
                                fontSize: '1.3em',
                                textTransform: 'none',
                                fontWeight: '500',
                            }}
                            >Позвонить</Button>
                        </Grid>
                        {
                          videoCallUsers_.includes(localStorage.getItem('username')) &&
                          <Grid item>
                            <Button
                            onClick={() => initZoomVideoCall(chose_phone)}
                            variant="contained"
                            fullWidth
                            color="success"
                            style={{
                                width: '100%',
                                fontSize: '1.3em',
                                textTransform: 'none',
                                fontWeight: '500',
                            }}
                            >Видеозвонок</Button>
                        </Grid>
                        }
                    </Grid>
                </Grid>
            }
            <Widget
            setIframeActive={setIframeActive}
            handleSetGotMessage={handleSetGotMessage}
            handleSetCallData={handleSetCallData}
            setCallButtonState={setCallButtonState}
            loaded={page_loaded}
            patient_id={patient_id}
            initBrightPatternInterface={initBrightPatternInterface}
            />
            {
                patient_id === data?.patient_id &&
                <>
                    <Patient
                    notify={notify}
                    patient_id={patient_id}
                    handleClosePatient={togglePatientModalOpen}
                    key={patient_modal_key}
                    toggled={is_patient_modal_open}
                    />
                    <Monitoring
                    notify={notify}
                    patient_id={patient_id}
                    handleCloseMonitoring={toggleMonitoringModalOpen}
                    key={monitoring_modal_key}
                    toggled={is_monitoring_modal_open}
                    />
                    <Notification
                    isOpen={notification.open}
                    type={notification.type}
                    message={notification.text}
                    onClose={notify}
                    />
                </>
            }
        </Grid>
    )
}