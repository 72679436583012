const isAllParametersOfObjectIsNull = (data) => {
    let object_keys = Object.keys(data)
    for (let i=0; i<object_keys.length; i++){
        if (data[object_keys[i]]){
            if (!data[object_keys[i]?.name]){
                return false
            }
            return false
        }

    }
    return true
}


export default isAllParametersOfObjectIsNull