import React from 'react';
import { Route, Navigate } from 'react-router-dom';


const RouteGuard = ({ children }) => {
 
   function hasJWT() {
        return localStorage.getItem("access") ? true : false
   }
   return (
        hasJWT() 
        ?
        children
        :
        <Navigate to={{ pathname: '/login' }} />
   );
};

export default RouteGuard;