const is_busy_messages = [
    'DIAL_PANEL_STATE',
    // 'SELECT_SERVICE_BY_ID',
    '_O_CALL_DIALING',
    'INTERACTION_RENDERED',
    // 'CONVERSATION_CONTROL',
    // 'INTERACTION_SELECTED',
    // 'SELECT_DISPOSITION_BY_ID',
    'HIDE_RESCHEDULE_FORM',
    'HIDE_CONFERENCE_PARTY_LIST',
    'CONVERSATION_LIST',
]


export default is_busy_messages