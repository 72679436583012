import axios from "axios";


const AXIOS_URL = process.env.REACT_APP_AXIOS_URL


const axios_token = axios.create({
    baseURL: `${AXIOS_URL}/auth`,
  })

  
export default async function apiToken(login, password){
    const credentials = {
        username: login,
        password: password,
    }
    const request = await axios_token.post(`token/`, credentials)
    return request.data
    
}