import axios from 'axios'


const AXIOS_URL = process.env.REACT_APP_AXIOS_URL


const axiosConfig = {
    baseURL: `${AXIOS_URL}/api/ag`,
    timeout: 30000,
}
const ag_axios = axios.create(axiosConfig)





// ag_axios.interceptors.response.use(response => {
//     return response;
// }, error => {
//    if (error.response.status === 401) {
//     localStorage.clear()
//     window.location.href = "/login";
//    }
//    return error;
//  });


export default ag_axios