import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
// import refreshToken from 'api/refresh-token';
import axios from 'axios'
import ag_axios from './axios'


export default async function refreshToken(refresh) {
  const credentials = {
    refresh: refresh,
  }
  const request = await axios_token.post(`token/refresh/`, credentials)
  return request
}

const removeTokensFromLocalStorage = () => {
  localStorage.removeItem('access')
  localStorage.removeItem('refresh')
}

const redirectToSignInPage = () => {
  window.location.href = '/login'
}

const AXIOS_URL = process.env.REACT_APP_AXIOS_URL

const axios_token = axios.create({
  baseURL: `${AXIOS_URL}/auth`,
})


ag_axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      try {
        return refreshToken(localStorage.getItem('refresh'))
          .then((res) => {
            localStorage.setItem('access', res.data.access)
            setAuthToken(res.data.access)
            error.config.headers['Authorization'] = 'Bearer ' + res.data.access
            return axios(originalRequest)
          })
          .catch((err) => {
            if (err.response.status === 401) {
              removeTokensFromLocalStorage()
              redirectToSignInPage()
            }
          })
      } catch (err) {
        removeTokensFromLocalStorage()
        redirectToSignInPage()
      }
    } else {
      return Promise.reject(error)
    }
  }
)

export const setAuthToken = (token) => {
  if (token) {
    return (ag_axios.defaults.headers.common['Authorization'] = `Bearer ${token}`)
  } else {
    return delete ag_axios.defaults.headers.common['Authorization']
  }
}
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)
reportWebVitals()
