import { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';



export default function AgChosePhone(props){
    if (!props.choices){
        return <></>
    }
    return (
        <Select
        size='small'
        style={{ minHeight: '5vh', width: '100%' }}
        fullWidth
        onChange={(event) => props.onChange(event.target.value)}
        >
            {
                props.choices.map((obj) => {
                    return <MenuItem value={obj.phone}>{obj.name + ' ' + obj.phone}</MenuItem>
                })  
            }
        </Select>
    )
}