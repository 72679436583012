export const getDateLowerFilterName = (columnName) => {
  switch (columnName) {
    case 'date_of_next_call':
      return 'further_past_date_of_next_call_filter'
    case 'date_of_last_call':
      return 'further_past_date_of_last_call_filter'
    default:
      return ''
  }
}
