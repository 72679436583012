import { Component } from 'react'
import Fab from '@mui/material/Fab';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import './widget.css';
import brightpattern_config from 'brightpattern-config'
import authenticated_messages from 'arterial_hypertension/views/patient_info/brightpattern-messages/authenticated-messages';
import is_not_authenticated_messages from 'arterial_hypertension/views/patient_info/brightpattern-messages/is-not-authenticated-messages';
import is_busy_messages from 'arterial_hypertension/views/patient_info/brightpattern-messages/is-busy-messages';


const _authenticated_messages = authenticated_messages
const _is_not_authenticated_messages = is_not_authenticated_messages
const _is_busy_messages = is_busy_messages


export default class Widget extends Component{
    constructor(props) {
        super(props);
        this.state = {
            widget_status: "moved_in",
            bp_widget_height: "0vh",
            bp_widget_width: "0vh",
            bp_widget_zIndex: "-1",
            bpspat_inited: false,
            restart_timeout: null,
        }
    }
    toggleBPWidget = () => {
        const widget = document.getElementById("bp_widget_iframe")
        if (this.state.widget_status === "moved_in"){
            this.setState({
                widget_status: "moved_out",
                bp_widget_height: "60vh",
                bp_widget_width: "40vh",
            })
        }
        else{
            this.setState({
                widget_status: "moved_in",
                bp_widget_height: "0vh",
                bp_widget_width: "0vh",
            })
        }
    }
    BrightPatternMessageEventHandler = (event) => {
        // event.preventDefault()
        // clearTimeout(this.state.restart_timeout)
        // this.setState({restart_timeout: setTimeout(() => {
        //     this.refreshBPlogic()
        // }, 10000)})
        let authenticated_messages = _authenticated_messages
        let is_not_authenticated_messages = _is_not_authenticated_messages
        let is_busy_messages = _is_busy_messages
        let url = process.env.NODE_ENV === 'development'
        ? brightpattern_config.prod_url
        :  brightpattern_config.url
        // window.bpspat.api.init(url)
        if (event.origin === url){
            this.props.handleSetGotMessage(true)
            // if (event.data.data){
                console.log(event)
            // }
            let command = ""
            try{
                command = JSON.parse(event.data).command
            }
            catch{
                // this.setCallButtonState(false)
                return 0
            }
            if (is_not_authenticated_messages.includes(command)){
                this.props.setCallButtonState(true)
            }
            if (authenticated_messages.includes(command)){
                this.props.setCallButtonState(false)
            }
            if (is_busy_messages.includes(command)){
                this.props.setCallButtonState(true)
            }
            // if (command === "CALL_ENDED" || command === "_CALL_ENDED"){
            //     this.props.setCallButtonState(false)
            //     let data = JSON.parse(event.data)
            //     this.props.handleSetCallData(data)
            // }
            if (command === "INTERACTION_COMPLETED"){   
                let data = JSON.parse(event.data)
                if (localStorage.getItem('global_id') !== data.global_id){
                    localStorage.setItem('global_id', data.global_id)
                    this.props.handleSetCallData(data)
                }
                this.props.setCallButtonState(false)
            }
            // {"command":"STATE_CHANGED", "data":{"status":"ready", "reason":""}}'
            if (command === "STATE_CHANGED"){
                let data = JSON.parse(event.data)
                if (data.status === "ready"){
                    this.props.setCallButtonState(false)
                }
            }
            // if (command === "HIDE_STATUS"){
            //     if (JSON.parse(event.data).data === 'Загрузка ...'){
            //         setCallButtonState(false)
            //     }
            // }
        }
    }
    createAndToggle = () => {
        if (!this.props.loaded){
            this.props.setIframeActive();
            setTimeout(() => {
                this.toggleBPWidget()
            }, 1000);
        }
        else{
            this.toggleBPWidget()
        }
    }
    
    refreshBPlogic = () => {
        document.getElementById('repeater').src = document.getElementById('repeater').src
        window.bpspat.api.init()
    }
    
    componentDidUpdate(){
        // this.setState({restart_timeout: setTimeout(() => {
        //     this.refreshBPlogic()
        // }, 10000)})

        if (!isNaN(parseInt(this.props.patient_id)) && !this.state.bpspat_inited){
            this.setState({bpspat_inited: true}, () => {
                let url = process.env.NODE_ENV === 'development'
                ? brightpattern_config.prod_url
                :  brightpattern_config.url
                window.bpspat.api.init(url)
                window.addEventListener("message", this.BrightPatternMessageEventHandler)
            })
        }
    }
    componentWillUnmount(){
        window.removeEventListener("message", this.BrightPatternMessageEventHandler)
    }
    
    #TODO
    // auto_login_bp_test = () => {
    //     let data = {
    //         grant_type: "client_credentials",
    //         client_id: "developer",
    //         client_secret: "bxUeIc5ZHRMjcomc9Ordsz7hp4B3y0EOoB8sEZ0UdN5uA2Hw0IVzadRZ6mGrpYWr",
            
    //     }
    //     axios({
    //         method: "post",
    //         url: "https://smart-ticket.kz/configapi/v2/oauth/token",
    //         data: data,
    //         headers: {'Content-Type': 'x-www-form-urlencoded'},
    //       })
    //       .then((response) => {
    //         console.log(response)
    //       })
    // }
    
    render(){
        return(
            <>
                <div id="bp_widget" style={{ width: this.state.bp_widget_width, height: this.state.bp_widget_height, zIndex: "10"}}>
                    {
                        this.props.loaded &&
                            <div id="bp_widget_iframe" className={this.state.widget_status}> 
                            <iframe 
                            id="repeater" 
                            style={{ width: "40vh", height: "60vh" }} 
                            src={ 
                                process.env.NODE_ENV === 'development' 
                                ? brightpattern_config.prod_repeater 
                                :  brightpattern_config.repeater
                            } 
                            frameborder="0"></iframe>
                        </div>
                    }
                    <Fab 
                    style={{ zIndex: "10 !important" }} 
                    id="bp_widget_button" 
                    onClick={() => this.createAndToggle()}>
                        <LocalPhoneIcon />
                    </Fab>
                </div>
            </>
        )
    }
}

