/* eslint-disable import/no-duplicates */
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { DataGrid as DataGridBase, ruRU, GridColumnMenu } from '@mui/x-data-grid'
import styled from '@emotion/styled'
import { darken } from '@mui/material'
import './table.css'
import React from 'react'
import { NumberInputValue, NumberInputRange, DateInputValue, DateInputRange, SelectInputValue } from './elements'
import MultiSelectInputValue from './elements/multi-select-input-value'
import { getMultiSelectInputValueOperator } from './operators/multi-select-input-value-operator'
import {
  // getNumberInputValueOperator,
  // getNumberInputRangeOperator,
  getDateInputValueOperator,
  getDateInputRangeOperator,
  getSelectInputValueOperator,
} from './operators'
import {
  // getNumberFilterName,
  // getNumberLowerFilterName,
  // getNumberUpperFilterName,
  getDateFilterName,
  getDateLowerFilterName,
  getDateUpperFilterName,
  getSelectFilterName,
} from './utils'
import { LinearProgress } from '@mui/material'
import statusColors from './status_colors'

const getHoverBackgroundColor = (color) => darken(color, 0.1)

const borderWidth = '3px'

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
      }}
    />
  )
}

const DataGrid = styled(DataGridBase)(({ theme }) => ({
  border: 'none',
  // eslint-disable-next-line quotes
  [`& .MuiDataGrid-cell`]: {
    // border: 'none',
  },
  // eslint-disable-next-line quotes
  [`& .MuiDataGrid-columnHeaders`]: {
    backgroundColor: 'unset',
  },
  // eslint-disable-next-line quotes
  [`& .MuiDataGrid-virtualScroller`]: {
    minHeight: '74vh',
  },
  // eslint-disable-next-line quotes
  [`& .MuiDataGrid-main`]: {
    border: '1px solid #ced4da',
    borderRadius: '20px',
  },
  // eslint-disable-next-line quotes
  [`& .MuiDataGrid-row`]: {
    marginTop: '15px',
    borderRadius: '5px',
    marginBottom: '15px',
    // paddingTop: '15px',
    // paddingBottom: '15px',
  },
  // eslint-disable-next-line quotes
  [`& .pregnants-list-theme--orange`]: {
    backgroundColor: 'rgba(255, 120, 23, 0.27)',
    border: `${borderWidth} solid rgba(255, 79, 23, 1)`,
    '&:hover': {
      backgroundColor: getHoverBackgroundColor('rgba(255, 120, 23, 0.27)'),
    },
  },
  // eslint-disable-next-line quotes
  [`& .pregnants-list-theme--red`]: {
    backgroundColor: 'rgba(226, 40, 40, 0.27)',
    border: `${borderWidth} solid rgba(226, 40, 40, 1)`,
    '&:hover': {
      backgroundColor: getHoverBackgroundColor('rgba(226, 40, 40, 0.27)'),
    },
  },
  // eslint-disable-next-line quotes
  [`& .pregnants-list-theme--yellow`]: {
    backgroundColor: 'rgba(255, 179, 32, 0.27)',
    border: `${borderWidth} solid rgba(255, 181, 71, 1)`,
    '&:hover': {
      backgroundColor: getHoverBackgroundColor('rgba(255, 179, 32, 0.27)'),
    },
  },
  // eslint-disable-next-line quotes
  [`& .pregnants-list-theme--green`]: {
    backgroundColor: 'rgba(49, 189, 88, 0.27)',
    border: `${borderWidth} solid rgba(0, 189, 53, 1)`,
    '&:hover': {
      backgroundColor: getHoverBackgroundColor('rgba(49, 189, 88, 0.27)'),
    },
  },
  // eslint-disable-next-line quotes
  [`& .pregnants-list-theme--purple`]: {
    backgroundColor: 'rgba(125, 77, 186, 0.27)',
    border: `${borderWidth} solid rgba(96, 1, 220, 1)`,
    '&:hover': {
      background: getHoverBackgroundColor('rgba(125, 77, 186, 0.27)'),
    },
  },
  // eslint-disable-next-line quotes
  [`& .pregnants-list-theme--pink`]: {
    backgroundColor: 'rgba(255, 86, 116, 0.27)',
    border: `${borderWidth} solid rgba(255, 117, 167, 1)`,
    '&:hover': {
      backgroundColor: getHoverBackgroundColor('rgba(255, 86, 116, 0.27)'),
    },
  },
  // eslint-disable-next-line quotes
  [`& .pregnants-list-theme--turquoise`]: {
    backgroundColor: 'rgba(34, 202, 192, 0.27)',
    border: `${borderWidth} solid rgba(36, 189, 199, 1)`,
    '&:hover': {
      backgroundColor: getHoverBackgroundColor('rgba(34, 202, 192, 0.27)'),
    },
  },
  // eslint-disable-next-line quotes
  [`& .pregnants-list-theme--gray`]: {
    backgroundColor: 'rgb(236,236,236)',
    border: `${borderWidth} solid rgb(191,191,191)`,
    '&:hover': {
      backgroundColor: getHoverBackgroundColor('rgb(236,236,236)'),
    },
  },
  // eslint-disable-next-line quotes
  [`& .pregnants-list-theme--undefined`]: {
    border: '1px solid #ced4da',
  },
}))

export default function Table(props) {
  const theme = createTheme(ruRU)

  function openPregnantInfoModal(params) {
    props.setSelectedPregnantIIN(params.row.iin)
    props.setPregnantInfoModalShow(true)
    // navigate(params)
  }

  const columns = () => {
    return props.columns.map((col) => {
      switch (col.field) {
        case 'age':
        case 'date_of_next_call':
          return {
            ...col,
            filterOperators: [
              getDateInputValueOperator(DateInputValue, {
                handleFilterChange: props.handleFilterChange,
                getDateFilterName,
              }),
              getDateInputRangeOperator(DateInputRange, {
                sx: { width: '100px' },
                handleFilterChange: props.handleFilterChange,
                getDateLowerFilterName,
                getDateUpperFilterName,
              }),
            ],
          }
        case 'date_of_last_call':
          return {
            ...col,
            filterOperators: [
              getDateInputValueOperator(DateInputValue, {
                handleFilterChange: props.handleFilterChange,
                getDateFilterName,
              }),
              getDateInputRangeOperator(DateInputRange, {
                sx: { width: '100px' },
                handleFilterChange: props.handleFilterChange,
                getDateLowerFilterName,
                getDateUpperFilterName,
              }),
            ],
          }

        case 'pmsp':
          return {
            ...col,
            filterOperators: [
              getMultiSelectInputValueOperator(MultiSelectInputValue, {
                handleFilterChange: props.handleFilterChange,
                getSelectFilterName,
                fields: props.fields,
              }),
            ],
            sortable: false,
          }

        case 'id':
        case 'status_of_last_call':
          return {
            ...col,
            filterOperators: [
              getSelectInputValueOperator(SelectInputValue, {
                handleFilterChange: props.handleFilterChange,
                getSelectFilterName,
                fields: props.fields,
              }),
            ],
            sortable: false,
          }
        case 'iin':
          return {
            ...col,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
          }
        case 'full_name':
          return {
            ...col,
            filterable: false,
          }
        default:
          return col
      }
    })
  }

  const handleFilterModelChange = (data, { reason }) => {
    if (reason === 'deleteFilterItem') {
      props.resetFilters()
    }
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <DataGrid
          rowHeight={50}
          onPaginationModelChange={props.handlePageChange}
          onSortModelChange={props.handleSortChange}
          paginationModel={props.paginationModel}
          paginationMode='server'
          filterMode='server'
          sortingMode='server'
          editMode='row'
          onFilterModelChange={handleFilterModelChange}
          onRowDoubleClick={(params) => {
            openPregnantInfoModal(params)
            // navigateToPregnant(`card/${params.row.id}`)
          }}
          getRowClassName={(params) => `pregnants-list-theme--${statusColors[params.row.status_of_last_call?.name]}`}
          getRowId={(row) => row.index}
          initialState={{
            pagination: { paginationModel: props.paginationModel },
          }}
          disableRowSelectionOnClick
          columns={columns()}
          rows={props.rows}
          rowCount={props.totalItems}
          loading={props.loading}
          slots={{
            loadingOverlay: LinearProgress,
            columnMenu: CustomColumnMenu,
          }}
          slotProps={{
            baseFormControl: {
              sx: { width: 'auto' },
            },
          }}
          apiRef={props.apiRef}
        />
      </ThemeProvider>
    </div>
  )
}
