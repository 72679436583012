const patient_fields = {
    diagnosis: [
        {
          name: ''
        }
    ],
    degree_of_arterial_hypertension: [
        {
          name: ''
        }
    ],
    risk_factor: [
        {
          name: ''
        }
    ],
    degree_of_risk: [
        {
          name: ''
        }
    ],
    gender: [
        {
          name: ''
        }
    ],
    accompanying_illness: [
        {
          name: ''
        }
    ],
    pmsp: [
        {
          name: ''
        }
    ],
    pmsp_area: [
        {
          name: ''
        }
    ],
    drugs: [
        {
          name: ''
        }
    ]
}


export default patient_fields