import { Dialog, Grid, TextField, Typography, Button } from "@mui/material";
import isOnlyNumbersIncluded from "arterial_hypertension/custom-methods/is-only-numbers-included";
import { useEffect, useState } from "react";


export default function AgPressurePopup(props){
    const [value, setValue] = useState(props.value)
    const [systolic, setSystolic] = useState('')
    const [diastolic, setDiastolic] = useState('')
    const [systolic_error, setSystolicError] = useState(false)
    const [diastolic_error, setDiastolicError] = useState(false)
    
    
    useEffect(() => {
        setValue(props.value)
    }, [props.value])
    
    useEffect(() => {
        if (value){
            setSystolic(props.value.split('/')[0])
            setDiastolic(props.value.split('/')[1])
        }
    }, [value])
 
    const setSystolicWithValidation = (val) => {
        if (isOnlyNumbersIncluded(val)){
            if (val.length <= 3){
                setSystolic(val)
            }
        }
    }
    const setDiastolicWithValidation = (val) => {
        if (isOnlyNumbersIncluded(val)){
            if (val.length <= 3){
                setDiastolic(val)
            }
        }
    }
    if (!props.toggled){
        return <></>
    }
    const saveAg = () => {
        if (!systolic){
            return setSystolicError(true)
        }
        setSystolicError(false)
        if (!diastolic){
            return setDiastolicError(true)
        }
        setDiastolicError(false)
        props.handleSave(systolic + '/' + diastolic)
        props.handleClose()
    }
    return (
        <Dialog onClose={props.handleClose} open={props.toggled}>
            <Grid container p={3} style={{ width: '15vw',  }}>
                <Grid item xs={12} lg={12} md={12} container justifyContent='center'>
                    <Grid xs={4} lg={4} md={4} item textAlign='end'>
                        <TextField 
                        size='small'
                        error={systolic_error}
                        value={systolic} 
                        onChange={(e) => setSystolicWithValidation(e.target.value)} 
                        />
                    </Grid>
                    <Grid xs={1} lg={1} md={1} item textAlign='center'>
                        <Typography 
                        style={{fontSize: '20px'}}
                        >
                            /
                        </Typography>
                    </Grid>
                    <Grid xs={4} lg={4} md={4} item>
                        <TextField 
                        error={diastolic_error}
                        size='small' 
                        value={diastolic} 
                        onChange={(e) => setDiastolicWithValidation(e.target.value)} 
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12} md={12} container mt={2} justifyContent='center'>
                    <Button
                    onClick={() => saveAg()}
                    variant='contained'
                    color='primary'
                    style={{ fontWeight: 'bold' }}
                    >Сохранить</Button>
                </Grid>
            </Grid>

        </Dialog>
    )

}