const parseYearByIinNumber = (iin) => {
    const iin_number = iin.charAt(6)
    const year_second_part = iin.charAt(0) + iin.charAt(1)
    let year_first_part = ''
    const eighteen_numbers = ['1', '2']
    const nineteen_numbers = ['3', '4']
    const twenty_numbers = ['5', '6']
    if (eighteen_numbers.includes(iin_number)){
        year_first_part = '18'
    }
    if (nineteen_numbers.includes(iin_number)){
        year_first_part = '19'
    }
    if (twenty_numbers.includes(iin_number)){
        year_first_part = '20'
    }
    return year_first_part + year_second_part
}


export default parseYearByIinNumber