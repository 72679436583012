const statusColors = {
  'АГ (Перезвонить)': 'yellow',
  'АГ (Не дозвон)': 'gray',
  'АГ (Обработанный звонок)': 'green',
  'АГ (Не корректный номер)': 'purple',
  'АГ (Не беспокоить)': 'red',
  'АГ (Усиленный мониторинг)': 'red',
  'АГ (Госпитализирован)': 'red',
}

export default statusColors
