const monitoring_fields = {
    status_of_screening: [
      {
        name: ''
      }
    ],
    frequency_of_increased_blood_pressure: [
      {
        name: ''
      }
    ]
  }


export default monitoring_fields