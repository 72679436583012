import Modal from 'react-bootstrap/Modal'
import PatientInfo from 'arterial_hypertension/views/patient_info/patient_info'

export function PatientInfoModal({ show, iin, onHide }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size='xl'
      enforceFocus={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title >Заполнить информацию о пациенте</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PatientInfo iin={iin} closePregnantInfoModal={onHide} />
      </Modal.Body>
    </Modal>
  )
}
