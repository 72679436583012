import Router from './router/router';
import './App.css';
import Notification from './notification/notification';
import { useState,  } from 'react';
import CoolSidebar from 'sidebar/sidebar';

// (c) Copyright 2023 VASSILIY MYASNIKOV, all rights reserved.


function App() {
  const [notification, setNotification] = useState({open: false, text: '', type: ''})
  
  
  return (
    <div className="App">
      <CoolSidebar />
      <div className='content'>
        <Router className="content"></Router>
      </div>

    </div>
  );
}

export default App;
