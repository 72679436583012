import {useEffect, useState} from "react";
import { 
	Grid, 
	Container, 
	Typography, 
	FormControl, 
	InputLabel, 
	InputAdornment, 
	FilledInput,
	Button,
	IconButton,
} from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from "@emotion/react";
import { setAuthToken } from 'index';
import { useCallback } from 'react';
import { useRef } from 'react';
import PersonIcon  from '@mui/icons-material/Person';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility';
import apiToken from "../../api/login/api-token";
import './login.css';


const BASE_URL =  process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_BASE_URL : process.env.REACT_APP_PROD_BASE_URL


const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

const useHasChanged= (value) => {
	const prevVal = usePrevious(value)
	return prevVal !== value
}



export const Login = () => {
	
	const [password_visible, setPasswordVisibility] = useState(false)
	const [login, setLogin] = useState("")
	const [password, setPassword] = useState("")
	const [login_error, setLoginError] = useState(false)
	const login_has_changed = useHasChanged(login)
	const submit = () => apiToken(login, password)
	.then((res) => {
		if (res.access){
			console.log(res)
			localStorage.setItem("access", res.access)
			setAuthToken(res.access)
			localStorage.setItem("refresh", res.refresh)
			localStorage.setItem("full_name", res.full_name)
			localStorage.setItem("username", login)
			window.location.href = '/'
		}
	})
	.catch((err) => {
		console.log(err.response)
		if (err.response?.status === 401){
			setLoginError(true)
			// notify("error", "Неверные учетные данные")
		}
        else{
            // notify("error", "Ошибка авторизации. Свяжитесь со службой поддержкии")
        }
	})
	// const notify = (type, message, close) => {
	// 	if (close){
	// 			setNotificationOpen(false)
	// 	}
	// 	else{
	// 		setNotificationType(type)
	// 		setNotificationMessage(message)
	// 		setNotificationOpen(true)
	// 		setNotificationTimeout(setTimeout(() => {setNotificationOpen(true)}, 1500))
	// 	 }
	// }
	useEffect(() => {
		if (localStorage.getItem("access")){
			window.location.href = "/statistics"
		}
		if (login_error){
			if (login_has_changed){
				setLoginError(false)
			}
		}
		// if (notification_open){
			// clearTimeout(notification_timeout)
		// }
	})
	

 return (
		<>
			{/* <Notification onClose={notify} open={notification_open} message={notification_message} type={notification_type} /> */}
			<Container     
			style={{
				position: 'absolute', left: '50%', top: '40%',
				transform: 'translate(-50%, -50%)'
			}}>
					<Grid gap={3} container>
						
							<Grid item xs={12} lg={12} md={12}>
									{/* <img src={LoginLogo} /> */}
							</Grid>
							
							<Grid item xs={12} lg={12} md={12}>
								<Typography variant="h6">Вход в портал МИС</Typography>
							</Grid>
							
							<Grid mt={4} item xs={12} lg={12} md={12}>
								<FormControl error={login_error}>
									<InputLabel>Логин</InputLabel>
									<FilledInput
									id="login"
									size="small"
									value={login}
									onChange={(event) => setLogin(event.target.value)}
									endAdornment={
										<InputAdornment position="end">
											<div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
												<PersonIcon></PersonIcon>
											</div>
										</InputAdornment>
									}
									></FilledInput>
								</FormControl>
							</Grid>
							
							<Grid item xs={12} lg={12} md={12}>
								<FormControl error={login_error}>
										<InputLabel>Пароль</InputLabel>
										<FilledInput
										id="password"
										type={password_visible ? "text" : "password"}
										size="small"
										value={password}
										onChange={(event) => setPassword(event.target.value)}
										onKeyUp={(event) => event.key === "Enter" && submit(login, password)}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
												onClick={() => setPasswordVisibility(!password_visible)}
												>
													{ password_visible ? <VisibilityOffIcon /> : <VisibilityIcon /> }
												</IconButton>                              
											</InputAdornment>
										}
										></FilledInput>
									</FormControl>
							</Grid>
							
							<Grid mt={4} item xs={12} lg={12} md={12}>
								<Button style={{ width: "13%", fontWeight: "bold" }} onClick={() => submit(login, password)} variant="contained" color="primary">Войти</Button>
							</Grid>
							
					</Grid>
			</Container>
		</>
 )

 

 
 
}