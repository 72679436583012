import { Grid, Dialog, TextField, Typography, Checkbox, Button, Card, Divider} from "@mui/material";
import styled from "@emotion/styled";
import validation_obj from "./validation/validation-obj";
import phone_has_full_prefix from "./validation/phone-has-full-prefix";
import phone_has_no_prefix from "./validation/phone-has-no-prefix";
import phone_has_only_plus from "./validation/phone-has-only-plus";
import phone_is_valid from "./validation/phone-is-valid";
import phone_name_is_valid from "./validation/phone-name-is-valid";
import { Component } from "react";
import phone_has_only_number_after_plus from "./validation/phone-has-only-numbers-after-plus";
import phone_not_equals_empty_space from "./validation/phone-not-equals-empty-space";
import phone_has_at_least_one_symbole from "./validation/phone-has-at-least-one-symbole";
import phone_has_only_cyrillic_letters from "./validation/phone-has-only-cyrillic-letters";
import phone_is_empty_with_space_excluding from "./validation/phone-is-not-empty-with-space-excluding";
import './phone-modal.css'
import removeItemByIndex from "arterial_hypertension/custom-methods/remove-item-by-index";


const Title = styled(Typography)({
    fontSize: "23px",
    fontWeight: "bold",
})

const TextValue = styled(Typography)({
    fontSize: "18px",
    width: "100%",
    display: "block",
    height: "40px",
})

const TextLabel = styled(Typography)({
    fontWeight: "bold",
    fontSize: "18px",
})


export default class PhoneEdit extends Component{
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            is_valid: true,
            phones: this.props.phones,
            phone: '',
            phone_name: '',
            debounce_handler: '',
            validation: validation_obj,
            edit_index: this.props.edit_index,
        }
    }
    componentDidUpdate(prevProps, prevState){
        if (
            (
                (prevProps.toggled !== this.props.toggled) 
                || 
                !isNaN(parseInt(this.props.edit_index))
                ||
                JSON.stringify(this.props.phones) !== JSON.stringify(this.state.phones)
            ) && this.props.phones.length > this.props.edit_index){
            
            let prop_phone = this.props.phones[this.props.edit_index].phone
            let prop_phone_name = this.props.phones[this.props.edit_index].name
            console.log(prop_phone, prop_phone_name)
            if (this.state.phone !== prop_phone && this.state.phone_name !== prop_phone_name){
                this.setState({
                    phones: this.props.phones,
                    phone: prop_phone,
                    phone_name: prop_phone_name,
                })
            }
        }
    }
    debouncedHandleChange = () => {
        clearTimeout(this.state.debounce_handler)
        this.setState({debounce_handler: setTimeout(() => {
                                this.handlePhoneAndPhoneNameChanged()    
                                }, 1000)})
        
        
    }
    handleClose = (event, reason) => {
        return reason && reason === "backdropClick" ? this.props.toggleEditPhoneModal(undefined) : 0
    }
    handleConfirm = () => {
        let phone = this.state.phone
        let phone_name = this.state.phone_name
        this.props.editPhone({phone: phone, name: phone_name}, this.props.edit_index)
        this.props.toggleEditPhoneModal(undefined)
    }
    handlePhoneAndPhoneNameChanged = () => {
        return (
            phone_is_valid(this.state.phone) && phone_name_is_valid(this.state.phone_name) 
            ? this.setState({is_valid: true})
            : this.setState({is_valid: false})
            )
    }
    setPhoneWithValidation = (val) => {
        let phone = this.state.phone
        let phone_helper_text = this.state.validation.phone_helper_text
        if (val){
            if (phone_has_only_number_after_plus(val)){
                if (val.length === 12 && phone_has_full_prefix(val)){
                    phone = val
                    phone_helper_text = ""
                }
                else if (val.length < 12){
                    if (phone_has_full_prefix(val)){
                        phone = val
                    }
                    else if(phone_has_only_plus(val)){
                        phone = val.replace("+", "+7")
                    }
                    else if (phone_has_no_prefix(val)){
                        phone = "+7" + val.slice(1)
                    }
                }
                else if (val.length > 12){
                    // setModalPhoneHelperText("Не более 12 символов")
                }
                else{
                    phone_helper_text = "Введите номер в формате +7XXXXXXXXXX"
                }
            }
        }
        else{
            phone = "+7" + val
            this.setState({phone: phone})
        }
        return this.setState({
            phone: phone,
            validation: {
                ...this.state.validation, phone_helper_text: phone_helper_text
            }
        }, () => this.debouncedHandleChange())
    }
    replaceMultipleEmptySpacesWithOneEmptySpace = (val) => {
        return val.replace(/\s+/g, ' ')
    }
    handlePhoneNameOnBlur = () => {
        return this.setState({phone_name: this.replaceMultipleEmptySpacesWithOneEmptySpace(this.state.phone_name)})
    }
    setPhoneNameWithValidation = (val) => {
        let phone_name = this.state.phone_name
        if (val.length <= 20){
            if (phone_has_only_cyrillic_letters(val)){
                phone_name = val
            }
        }
        else{
            return 0
        }
        return this.setState({
            phone_name: phone_name,
        }, () => {this.assertIsPhoneNameValid(phone_name); this.debouncedHandleChange()})
    }
    assertIsPhoneNameValid = (val) => {
        if (phone_is_empty_with_space_excluding(val)){
            return this.setState({
                validation: this.state.validation, phone_name_helper_text: "Поле должно содержать хотя бы 3 символа",
            })
        }
    }
    setIsMain = () => {
        this.setState({is_main: !this.state.is_main})
    }
    render(){
        if (!this.props.toggled){
            return <></>
        }
        return (
            <Dialog onClose={this.handleClose} open={this.props.toggled} style={{ width: "28%", marginLeft: "8%" }}>
                <Grid p={2} py={4} container gap={2}>
                    <Grid item container md={12} xs={12} lg={12} spacing={1} style={{ minHeight: "7vh"}}>
                        <Grid item>
                            <TextLabel>Название: </TextLabel>
                        </Grid>
                        <Grid item>
                            <TextField
                            id="modal_phone_name"
                            onChange={(e) => {this.setPhoneNameWithValidation(e.target.value)}}
                            value={this.state.phone_name}
                            helperText={this.state.validation.phone_name_helper_text}
                            onBlur={() => this.handlePhoneNameOnBlur()}
                            inputProps={{
                                style: {height: "15px"}
                            }}
                            size="small"
                            />
                        </Grid>
                    </Grid>
                    <Grid item md={12} xs={12} lg={12}>
                        <Divider style={{ border: "1px solid #DBDBDB" }} />
                    </Grid>
                    <Grid item container xs={12} lg={12} md={12} spacing={1} style={{ minHeight: "7vh"}}>
                        <Grid xs={5} lg={5} md={5} item>
                            <TextLabel>Номер телефона: </TextLabel>
                        </Grid>
                        <Grid xs={6} lg={6} md={6} item>
                            <TextField
                            id="modal_phone_number"
                            inputProps={{
                                style: {
                                  height: "15px",
                                }
                            }}
                            size="small"
                            helperText={this.state.phone_helper_text}
                            onChange={(e) => {this.setPhoneWithValidation(e.target.value)}}
                            value={this.state.phone}
                            >
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid item md={12} xs={12} lg={12}>
                        <Divider style={{ border: "1px solid #DBDBDB" }} />
                    </Grid>
                    <Grid textAlign="center" item xs={12} lg={12} md={12}>
                        <Button 
                        disabled={!this.state.is_valid}
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none", fontSize: "16px" }}                    
                        onClick={() => {
                            // props.addPhone(modal_phone, modal_phone_name, is_main, props.subject)
                            this.handleConfirm();
                        }}
                        >
                        Сохранить
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
        )
    }
}