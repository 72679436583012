export function ColumnsProperties() {
  return [
    {
      field: 'full_name',
      headerName: 'ФИО',
      width: 300,
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: 'iin',
      headerName: 'ИИН',
      width: 150,
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: 'date_of_next_call',
      headerName: 'Дата следующего звонка',
      width: 300,
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: 'pmsp',
      headerName: 'ПМСП',
      width: 150,
      valueGetter: (params) => {
        return params.value.name
      },
    },
    {
      field: 'status_of_last_call',
      headerName: 'Статус последнего звонка',
      width: 300,
      valueGetter: (params) => {
        return params.value?.name
      },
    },
    {
      field: 'date_of_last_call',
      headerName: 'Дата последнего звонка',
      width: 300,
    },
  ]
}
