import { useState } from "react"
import { DataGrid } from "@mui/x-data-grid"


export default function AgHistoryTable(props){
    const [rows, setRows] = useState(props.rows)
    const [cols, setCols] = useState(props.columns)
    
    return (
        <DataGrid
        rows={rows}
        columns={cols}
        disableRowSelectionOnClick
        hideFooterPagination={true} 
        hideFooterSelectedRowCount={true}
        hideFooter
        />
    )
}