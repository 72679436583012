import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import dayjs from 'dayjs'
import { TextField } from '@mui/material';
import moment from 'moment/moment';


export default function AgDatepicker(props){
    return (
        <LocalizationProvider
        id={props.id}
        adapterLocale="ru"
        dateAdapter={AdapterDayjs}
        >
            <DatePicker
            id={props.id}
            disabled={props.disabled}
            // readOnly={props.disabled}
            disableOpenPicker={props.disableOpenPicker}
            onChange={
                (val) => {
                    const d = new Date(val).toLocaleDateString('en-CA');
                    props.onChange(d)
                }
            }
            slotProps={{
                textField: {
                    size: "small",
                    onBlur: () => {try{props.onBlur()} catch(err){console.log(err)}},
                    helperText: props.helperText || "",
                    error: props.error || false,
                },
           }}
            value={ dayjs(moment(props.value, "YYYY-MM-DD")) || '' }
            >
            </DatePicker>
        </LocalizationProvider>
    )
}