import phone_is_empty_with_space_excluding from "./phone-is-not-empty-with-space-excluding"


const phone_name_is_valid = (phone_name) => {
    if (!phone_name || phone_is_empty_with_space_excluding(phone_name)){
        return false
    }
    return 3 <= phone_name.length <= 20 && /^[А-яа-яA-za-z- ]*$/.test(phone_name)
}


export default phone_name_is_valid