import { Button, TextField as TextFieldBase, Typography } from "@mui/material"
import Ag from "../../elements/ag-datepicker";
import AgSelect from "../../elements/ag_select/ag-select";
import { Grid, Dialog } from "@mui/material";
import { useState, useEffect } from "react";
import AgMultiSelect from "../../elements/ag_multiselect/ag-multiselect";
import AgDatepicker from "../../elements/ag-datepicker";
import monitoring_fields from "./monitoring-fields";
import getMonitoring from "arterial_hypertension/api/monitoring/get-monitoring";
import getMonitoringHistory from "arterial_hypertension/api/monitoring/get-monitoring-history";
import AgHistoryTable from "arterial_hypertension/elements/ag-history-table";
import postMonitoringCreate from "arterial_hypertension/api/monitoring/post-monitoring-create";
import replaceEmptyParametersOfDataToNull from "arterial_hypertension/custom-methods/replace-empty-parameters-of-data-to-null";
import isAllParametersOfObjectIsNull from "arterial_hypertension/custom-methods/is-all-parameters-of-object-is-null";
import getMonitoringFields from "arterial_hypertension/api/monitoring/get-monitoring-fields";
import AgPressurePopup from "arterial_hypertension/elements/ag-pressure-popup";
import monitoring_history_columns from "./monitoring-history-columns";


const border_styles = {
    borderRadius: '20px',
    border: "1px solid #ced4da",
}
const border_styles_with_max_width = {
    borderRadius: '20px',
    border: "1px solid #ced4da",
    maxWidth: '49.3%',
}
const block_title_styles = {
    fontWeight: 'bold',
    fontSize: '30px',
}
const info_title_styles = {
    fontWeight: 'bold',
    fontSize: '23px',
}
const input_label_styles = {
    fontWeight: 'bold',
    fontSize: '15.3px',
}


const TextField = (props) => {
    return (
        <TextFieldBase
        fullWidth={props.fullWidth}
        size='small'
        value={props.value}
        disabled={props.disabled}
        onClick={() => {try{props.onClick()}catch(err){}}}
        onFocus={() => {try{props.onFocus()}catch(err){}}}
        onChange={(event) => props.onChange(event.target.value)}
        inputProps={{readOnly: props.readOnly}}
        />
    )
}


const monitoring_fields_obj = monitoring_fields
const monitoring_dialog_styles = { 
    maxWidth: '80vw', 
    maxHeight: '80vh',
    height: '60vh',
    marginTop: '4vh', 
    marginLeft: '14vw' 
}


export default function Monitoring(props){
    const [monitoring_fields, setMonitoringFields] = useState(monitoring_fields_obj)
    const [date_of_appointment_for_screening, setDateOfAppointmentForScreening] = useState(null)
    const [date_of_last_screening, setDateOfLastScreening] = useState(null)
    const [frequency_of_increased_blood_pressure, setFrequencyOfIncreasedBloodPressure] = useState({name: ''})
    const [highest_blood_pressure, setHighestBloodPressure] = useState('')
    const [pulse_beats_per_minute, setPulseBeatsPerMinute] = useState('')
    const [status_of_screening, setStatusOfScreening] = useState({name: ''})
    const [ad_popup_open, setAdPopupOpen] = useState(false)
    const [validation, setValidation] = useState({message: '', btn_text: 'Сохранить'})
    const [data, setData] = useState(undefined)
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState(monitoring_history_columns)
    const [ag_pressure_popup_key, setAgPressurePopupKey] = useState(1)
    const [blood_pressure_is_normal_ad_popup_is_open, setBloodPressureIsNormalAdPopup] = useState(false)
    const [blood_pressure_is_normal_ad_popup_key, setBloodPressureIsNormalAdPopupKey] = useState(false)
    const [blood_pressure_is_normal, setBloodPressureIsNormal] = useState('')
    
    const convertToNameStr = (arr) => {
        let res_array = []
        for (let i = 0; i < arr.length; i++){
            res_array.push({name: arr[i]})
        }
        return res_array
    }
    
    
    const toggleAdPopup = () => {
        setAdPopupOpen(!ad_popup_open)
    }
    
    const toggleBloodPressureIsNormalAdPopup = () => {
        setBloodPressureIsNormalAdPopup(!blood_pressure_is_normal_ad_popup_is_open)
    }
    
    
    const setHighestBloodPressureWithMask = (val) => {
        setHighestBloodPressure()
    }
    
    
    const handleGetMonitoring = () => {
        getMonitoring(props.patient_id)
        .then((res) => {
            if (res.data){
                setData(res.data)
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const handleGetMonitoringFields = () => {
        getMonitoringFields()
        .then((res) => {
            if (res.data){
                setMonitoringFields(res.data)
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const handleGetMonitoringHistory = (patient_id) => {
        getMonitoringHistory(patient_id)
        .then((res) => {
            if (res.data){
                let rows = res.data
                for (let i = 0; i < rows.length; i++) {
                    rows[i]['id'] = i
                }
                setRows(rows)
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }
    
    const handlePostMonitoringCreate = () => {
        let important_fields = {
            frequency_of_increased_blood_pressure: frequency_of_increased_blood_pressure,
            status_of_screening: status_of_screening,
            date_of_appointment_for_screening: date_of_appointment_for_screening,
            date_of_last_screening: date_of_last_screening,
            highest_blood_pressure: highest_blood_pressure,
            pulse_beats_per_minute: pulse_beats_per_minute,
            blood_pressure_is_normal: blood_pressure_is_normal,
            ad_popup_open: ad_popup_open,
        }
        let checked_important_fields = replaceEmptyParametersOfDataToNull(important_fields)
        if (isAllParametersOfObjectIsNull(checked_important_fields)){
            return setValidation({
                message: 'Для создания записи заполните хотя бы одно поле',
                btn_text: 'Ошибка',
            })
        }
        let data = {
            date_of_appointment_for_screening: date_of_appointment_for_screening,
            date_of_last_screening: date_of_last_screening,
            frequency_of_increased_blood_pressure: frequency_of_increased_blood_pressure === null ? {name: null} : frequency_of_increased_blood_pressure,
            highest_blood_pressure: highest_blood_pressure,
            pulse_beats_per_minute: pulse_beats_per_minute,
            status_of_screening: status_of_screening === null ? {name: null} : status_of_screening,
            blood_pressure_is_normal: blood_pressure_is_normal,
            patient_id: props.patient_id,
            doctor_username: localStorage.getItem('username')
        }
        let push_data = replaceEmptyParametersOfDataToNull(data)
        postMonitoringCreate(push_data)
        .then((res) => {
            if (res.data){
                props.handleCloseMonitoring()
            }
        })
        .catch((err) => {
            if (err.response.status === 400){
                props.notify('error', 'Для создания записи заполните хотя бы одно поле')
                // props.notify('error', JSON.stringify(err?.response?.data))
            }
            console.log(err)
        })
    }
    
    useEffect(() => {
        if (data){
            setDateOfAppointmentForScreening(data.date_of_appointment_for_screening)
            setDateOfLastScreening(data.date_of_last_screening)
            setFrequencyOfIncreasedBloodPressure(data.frequency_of_increased_blood_pressure)
            setHighestBloodPressure(data.highest_blood_pressure)
            setPulseBeatsPerMinute(data.pulse_beats_per_minute)
            setStatusOfScreening(data.status_of_screening)
            setBloodPressureIsNormal(data.blood_pressure_is_normal)
        }
    }, [data])
    
    useEffect(() => {
        if (props.patient_id && !isNaN(parseInt(props.patient_id))){
            handleGetMonitoringHistory(props.patient_id)
        }
    }, [props.patient_id])
    
    useEffect(() => {
        if (props.toggled){
            handleGetMonitoringFields()
            handleGetMonitoring()
        }
    }, [props.toggled])
    if (!props.toggled){
        return <></>
    }
    return (
        <Dialog 
        onClose={props.handleCloseMonitoring} 
        fullScreen 
        open={props.toggled} 
        style={monitoring_dialog_styles}
        >
            <Grid container p={3}>
                <Grid item xs={12} md={12} lg={12} container>
                    <Grid xs={6} md={6} lg={6} item>
                        <Typography style={block_title_styles}>Мониторинг</Typography>
                    </Grid>
                    <Grid textAlign='end' xs={6} md={6} lg={6} item>
                        <Typography style={{ cursor: 'pointer' }} onClick={() => props.handleCloseMonitoring()}>X</Typography>
                    </Grid>
                </Grid>
                <Grid xs={12} md={12} lg={12} item container style={border_styles} p={3}>
                    <Grid xs={12} md={12} lg={12} item container mt={1}>
                        <Grid xs={3} lg={3} md={3} item>
                            <Typography style={input_label_styles}>Дата последнего скрининга</Typography>
                            <AgDatepicker
                            value={date_of_last_screening}
                            onChange={setDateOfLastScreening}
                            />
                        </Grid>
                        <Grid xs={3} lg={3} md={3} item>
                            <Typography style={input_label_styles}>Дата записи на скрининг</Typography>
                            <AgDatepicker
                            value={date_of_appointment_for_screening}
                            onChange={setDateOfAppointmentForScreening}
                            />
                        </Grid>
                        <Grid xs={3} lg={3} md={3} item>
                            <Typography style={input_label_styles}>Статус скрининга</Typography>
                            <AgSelect
                            choices={monitoring_fields.status_of_screening}
                            value={status_of_screening}
                            onChange={setStatusOfScreening}
                             />
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={12} lg={12} item container mt={2} spacing={3} justifyContent="start">
                        <Grid xs={2} lg={2} md={2} item>
                            <Typography style={input_label_styles}>Частота повышения АД</Typography>
                            <AgSelect
                            choices={monitoring_fields.frequency_of_increased_blood_pressure}
                            value={frequency_of_increased_blood_pressure}
                            onChange={setFrequencyOfIncreasedBloodPressure}
                            />
                        </Grid>
                        <Grid xs={2} lg={2} md={2} item>
                            <Typography style={input_label_styles}>Макс высокое АД</Typography>
                            <TextField
                            onClick={toggleAdPopup}
                            value={highest_blood_pressure}
                            onChange={setHighestBloodPressure}
                            readOnly={true}
                            />
                            <AgPressurePopup
                            key={ag_pressure_popup_key}
                            handleClose={toggleAdPopup} 
                            value={highest_blood_pressure} 
                            handleSave={setHighestBloodPressure} 
                            toggled={ad_popup_open} 
                           />
                            
                        </Grid>
                        <Grid xs={2} lg={2} md={2} item>
                            <Typography style={input_label_styles}>Пульс уд/мин</Typography>
                            <TextField
                            value={pulse_beats_per_minute}
                            onChange={setPulseBeatsPerMinute}
                            />
                        </Grid>
                        <Grid xs={3} lg={3} md={3} item>
                            <Typography style={input_label_styles}>АД в норме</Typography>
                            <TextField
                            onClick={toggleBloodPressureIsNormalAdPopup}
                            value={blood_pressure_is_normal}
                            onChange={setBloodPressureIsNormal}
                            readOnly={true}
                            />
                            <AgPressurePopup
                            key={blood_pressure_is_normal_ad_popup_key}
                            handleClose={toggleBloodPressureIsNormalAdPopup} 
                            value={blood_pressure_is_normal} 
                            handleSave={setBloodPressureIsNormal} 
                            toggled={blood_pressure_is_normal_ad_popup_is_open} 
                           />
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={12} lg={12} item container justifyContent='flex-end'>
                        <Typography style={{color: 'red', fontWeight: 'bold', fontSize: '20px'}}>
                        {validation.message}
                        </Typography>
                        <Button
                        onClick={() => handlePostMonitoringCreate()}
                        size='small'
                        style={{ fontSize: '16px', fontWeight: 'bold', width: '15%', borderRadius: '6.7px' }}
                        color='primary'
                        variant='contained'
                        >{validation.btn_text}</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container p={3}>
            <Grid item xs={12} lg={12} md={12} container>
                    <Grid pl={3} xs={12} md={12} lg={12} item>
                        <Typography style={block_title_styles}>История</Typography>
                    </Grid>
                    <Grid xs={12} md={12} lg={12} item>
                        <AgHistoryTable columns={columns} rows={rows} />
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}