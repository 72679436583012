const report_fields_obj = {
    health_complaints: [
      {
        name: ''
      }
    ],
    taking_medications: [
      {
        name: ''
      }
    ],
    operator_request_status: [
      {
        name: ''
      }
    ],
    medical_procedures: [
      {
        name: ''
      }
    ],
    complaints_and_suggestions_about_pmsp_work: [
      {
        name: ''
      }
    ]
  }


export default report_fields_obj