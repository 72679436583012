import setNullIfObjectIsEmpty from "./set-null-if-object-is-empty"


const replaceEmptyParametersOfDataToNull = (data) => {
    const data_keys = Object.keys(data)
    const new_data = {}
    for (let i=0; i<data_keys.length; i++){
        if (data_keys[i] === 'frequency_of_increased_blood_pressure' || data_keys[i] === 'status_of_screening'){
            new_data[data_keys[i]] = data[data_keys[i]]?.name ? data[data_keys[i]] : {name: null}
        }
        else{
            new_data[data_keys[i]] = setNullIfObjectIsEmpty(data[data_keys[i]])
        }
    }
    return new_data
}


export default replaceEmptyParametersOfDataToNull