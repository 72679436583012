const setNullIfObjectIsEmpty = (obj) => {
    const all_boolean_false_types = ['', {name: ''}, undefined, null, false, NaN]
    if (all_boolean_false_types.includes(obj)){
        return null
    }
    else if (obj.length === 0){
        return null
    }
    else if (obj?.name === ""){
        return null
    }
    return obj
}


export default setNullIfObjectIsEmpty