import { Alert, Snackbar } from '@mui/material'

export default function Notification(props) {
  return (
    <Snackbar
      id='notification'
      open={props.isOpen}
      autoHideDuration={4000}
      onClose={() => props.onClose('', '', true)}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert variant='filled' severity={props.type}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}
