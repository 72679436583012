import React from 'react'
import { Routes, Route } from 'react-router-dom'
import RouteGuard from './route-guard'
import { Login } from '../arterial_hypertension/views/login/login'
import { history } from './history'
import AgMain from '../arterial_hypertension/views/main/ag-main'
import Zoom from 'arterial_hypertension/views/zoom/zoom'
import ZoomJoinUrl from 'arterial_hypertension/views/zoom_join_url/zoom-join-url'


const Router = () => {
  return (
    <Routes history={history}>
      <Route path='/login' element={<Login />}></Route>
      <Route
        path='/'
        element={
          <RouteGuard>
            <AgMain />
          </RouteGuard>
        }
      ></Route>
      <Route
        path='/zoom'
        element={
          <RouteGuard>
            <Zoom />
          </RouteGuard>
        }
      ></Route>
      <Route
        path='/zoom_redirect'
        element={
            <ZoomJoinUrl />
        }
      ></Route>
    </Routes>
  )
}

export default Router
