const authenticated_messages = [            
    'STATE_CHANGED',
    'WELCOME_DATA',
    'DEFAULT_COUNTRY',
    'CREATE_CHAT',
    'CHAT_HISTORY',
    'GET_DIRECTORY',
    '_BLIND_TRANSFER',
    '_CONSULT_TRANSFER',
    "AUTH_ACCEPT",
]


export default authenticated_messages