import { useState, useEffect, useReducer, Component } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, Typography } from '@mui/material';
import { FormHelperText } from '@mui/material';
import removeItemByIndex from 'arterial_hypertension/custom-methods/remove-item-by-index';


export default class AgPhones extends Component{
    constructor(props) {
        super(props);
        this.state = {
            choices: this.props.choices,
        }
    }
    
    removeThenUpdate = (index) => {
        let phones = this.state.choices
        if (phones.length === 1){
            return this.props.notify('error', 'Нельзя удалить единственный телефон')
        }
        let new_phones = removeItemByIndex(phones, index)
        this.props.removePhone(new_phones)
        // setChoices(new_phones)
    }
    
    componentDidUpdate(prevProps, prevState){
        if (JSON.stringify(this.props.choices) !== JSON.stringify(this.state.choices)){
            this.setState({choices: this.props.choices})
        }
    }
    render(){
        return (
            this.state.choices !== undefined && this.state.choices.length > 0
            ?
            <div>
                <Select
                id={this.props.id}
                error={this.props.error}
                defaultValue={0}
                value={0}
                size="small"
                style={{ width: "100%" }}
                slotProps={{
                    textField: {
                        size: "small",
                        helperText: this.props.helperText || "",
                        error: this.props.error,
                    },
                }}
                >
                {
                    this.state.choices.map((obj, index) => {
                        return (
                            <MenuItem
                            key={obj.phone}
                            selected={index === 0}
                            value={index}>
                                <Grid justifyContent='space-between' container>
                                    <Grid xs={6} lg={6} md={6} item>
                                        <Typography
                                        style={{ width: "100%" }}
                                        >
                                        {obj.name + ' ' + obj.phone}
                                        </Typography>
                                    </Grid>
                                    <Grid item container xs={2} lg={2} md={2}>
                                        <Grid xs={6} lg={6} md={6} style={{ alignItems: "center", display: "flex" }} item>
                                            <EditIcon style={{ color: "#0D6EFD" }} onClick={() => {this.props.toggleEditPhoneModal(index)}} />
                                        </Grid>
                                        <Grid xs={6} lg={6} md={6} style={{ alignItems: "center", display: "flex" }} item>
                                            <DeleteIcon style={{ color: "red" }} onClick={() => this.removeThenUpdate(index)} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        )
                    })  
                }
                </Select>
                <FormHelperText style={{ color: '#d32f2f' }}>{this.props.helperText}</FormHelperText>
            </div>
            :
            <div>
                <Select
                value=""
                error={this.props.error}
                size="small"
                style={{ width: "100%" }}
                slotProps={{
                    textField: {
                        size: "small",
                        helperText: this.props.helperText || "",
                        error: this.props.error,
                    },
                }}
                >
                </Select>
                <FormHelperText style={{ color: '#d32f2f' }}>{this.props.helperText}</FormHelperText>
            </div>
        )
    }    
}