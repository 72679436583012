const monitoring_history_columns = [
    {
        field: 'date_of_last_screening', 
        headerName: 'Дата последнего скриннинга', 
        width: 210,
    },
    {
        field: 'date_of_appointment_for_screening', 
        headerName: 'Дата записи на скрининг', 
        width: 210,
    },
    {
        field: 'status_of_screening', 
        headerName: 'Статус скрининга', 
        width: 210,
        valueGetter: (params) => {
            return params?.value?.name || ''
        }
    },
    {
        field: 'frequency_of_increased_blood_pressure', 
        headerName: 'Частота повышения АД', 
        width: 210,
        valueGetter: (params) => {
            return params?.value?.name || ''
        }
    },
    {
        field: 'highest_blood_pressure', 
        headerName: 'Макс высокое АД', 
        width: 150,
    },
    {
        field: 'pulse_beats_per_minute', 
        headerName: 'Пульс уд/мин', 
        width: 120,
    },
    {
        field: 'date_of_creation', 
        headerName: 'Дата создания', 
        width: 140,
    },
    {
        field: 'doctor_username', 
        headerName: 'Врач ТМЦ', 
        width: 210,
    },
]


export default monitoring_history_columns