import { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormHelperText } from '@mui/material';


export default function AgSelect(props){
    const [ choices, setChoices ] = useState(undefined)
    const [ select_value, setSelectValue ] = useState(undefined)
    useEffect(() => {
        if (props.choices !== choices){
            setChoices(props.choices)
            // console.log('PREGNANT_SELECT props.choices', props.choices, props.id)
        }
        if (props.value !== select_value){
            setSelectValue(props.value)
            // console.log('PREGNANT_SELECT props.value', props.value, props.id)
        }
    })
    const is_full_width = props.fullWidth === false ? false : true
    return (
        choices !== undefined && choices.length > 0
        ?
        <div>
            <Select
            id={props.id}
            fullWidth={is_full_width}
            size="small"
            error={props.error}
            multiple={props.multiple}
            value={select_value?.name || ""}
            onChange={(event) => {
                props.onChange({name: event.target.value})
            }}
            style={{ minWidth: '50%' }}
            >
            {
                choices.map((obj) => {
                    return <MenuItem value={obj.name}>{obj.name}</MenuItem>
                })  
            }
            </Select>
            <FormHelperText style={{ color: 'red' }}>{props.helperText}</FormHelperText>
        </div>
        :
        <div>
            <Select
            id={props.id}
            error={props.error || false}
            size="small"
            multiple={props.multiple}
            value={select_value || ""}
            onChange={(event) => {
                props.onChange(props.id, event.target.value)
            }}
            style={{ minWidth: '50%' }}
            >
            </Select>
        </div>
    )
}