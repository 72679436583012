import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { Container, Grid, Divider } from "@mui/material";
import { DataGrid as DataGridBase } from '@mui/x-data-grid';
import { useEffect } from "react";
import { useState } from "react";
import styled from "@emotion/styled";


export default function AgResultsTable(props){
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])
  
  const DataGrid = styled(DataGridBase)(({ theme }) => ({
      border: "none",
      [`& .MuiDataGrid-cell`]: {
        // border: "none",
      },
      [`& .MuiDataGrid-columnHeaders`]: {
        backgroundColor: "unset",
      },
      [`& .MuiDataGrid-columnHeaderTitle`]: {
        fontWeight: "bold",
        color: "black",        
      },
      [`& .MuiDataGrid-virtualScroller`]: {
        minHeight: "10vh"
      },
      [`& .MuiDataGrid-main`]: {
        border: "1px solid #ced4da",
        borderRadius: "20px",
      },
    }),
  )
  
  
  
  useEffect(() => {
    if (rows !== props.rows){
      setRows(props.rows)
    }
    if (columns !== props.columns){
      setColumns(props.columns)
    }
  })
  if (!Boolean(rows.length)){
    return <></>
  }
  return (
    <DataGrid
    rows={rows}
    columns={columns}
    // onRowClick={(row) => props.handleRowClick(row.pregnancy_id)}
    getRowId={(row) => row.patient_id}
    hideFooterPagination={true} 
    hideFooterSelectedRowCount={true}
    hideFooter
    disableRowSelectionOnClick
    editMode="row"
    />
  )
}