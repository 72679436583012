import { Grid, Dialog, TextField, Typography, Checkbox, Button, Card, Divider} from "@mui/material";
import styled from "@emotion/styled";
import validation_obj from "./validation/validation-obj";
import phone_has_full_prefix from "./validation/phone-has-full-prefix";
import phone_has_no_prefix from "./validation/phone-has-no-prefix";
import phone_has_only_plus from "./validation/phone-has-only-plus";
import phone_is_valid from "./validation/phone-is-valid";
import phone_name_is_valid from "./validation/phone-name-is-valid";
import { Component } from "react";
import phone_has_only_number_after_plus from "./validation/phone-has-only-numbers-after-plus";
import phone_has_at_least_one_symbole from "./validation/phone-has-at-least-one-symbole";
import phone_has_only_cyrillic_letters from "./validation/phone-has-only-cyrillic-letters";
import phone_is_empty_with_space_excluding from "./validation/phone-is-not-empty-with-space-excluding";
import './phone-modal.css'
import { ThirtyFpsSelect } from "@mui/icons-material";


const Title = styled(Typography)({
    fontSize: "23px",
    fontWeight: "bold",
})

const TextValue = styled(Typography)({
    fontSize: "18px",
    width: "100%",
    display: "block",
    height: "40px",
})

const TextLabel = styled(Typography)({
    fontWeight: "bold",
    fontSize: "18px",
})


export default class PhoneAdd extends Component{
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            phone: "+7",
            is_valid: false,
            phone_name: "",
            is_main: false,
            debounce_handler: '',
            validation: validation_obj,
        }
    }
    
    clearData = (callback) => {
        return this.setState({
            is_valid: false,
            phone: "+7",
            phone_name: "",
        }, () => callback)
    }
    handleClose = (event, reason) => {
        return reason && reason === "backdropClick" ? this.clearData(this.props.toggleAddPhoneModal()) : 0
    }
    handleConfirm = () => {
        let phones = this.props.phones
        let new_phones = []
        if (this.state.is_main){
            for (let i=0; i<phones.length; i++){
                phones[i].is_main = false
                new_phones.push(phones[i])
            }
        }
        else{
            new_phones = phones
        }
        let phones_only_phones = phones.map((el) => el.phone)
        if (phones_only_phones.includes(this.state.phone)){
            this.props.notify('error', 'Нельзя создать номер телефона, который уже существует')
            return this.props.toggleAddPhoneModal()
        }
        new_phones.push({phone: this.state.phone, name: this.state.phone_name, is_main: this.state.is_main})
        this.props.addPhone(new_phones)
        return this.clearData(this.props.toggleAddPhoneModal())
    }
    handlePhoneAndPhoneNameChanged = () => {
        return (
            phone_is_valid(this.state.phone) && phone_name_is_valid(this.state.phone_name) 
            ? this.setState({is_valid: true}) 
            : this.setState({is_valid: false})
            )
    }
    replaceMultipleEmptySpacesWithOneEmptySpace = (val) => {
        return val.replace(/\s+/g, ' ')
    }
    handlePhoneNameOnBlur = () => {
        return this.setState({phone_name: this.replaceMultipleEmptySpacesWithOneEmptySpace(this.state.phone_name)})
    }
    debouncedHandleChange = () => {
        clearTimeout(this.state.debounce_handler)
        this.setState({debounce_handler: setTimeout(() => {
                                this.handlePhoneAndPhoneNameChanged()    
                                }, 1000)})
        
        
    }
    setPhoneNameWithValidation = (val) => {
        let phone_name = this.state.phone_name
        if (val.length <= 20){
            if (phone_has_only_cyrillic_letters(val)){
                phone_name = val
            }
        }
        else{
            return 0
        }
        return this.setState({
            phone_name: phone_name,
        }, () => this.setState({
            validation: {
                ...this.state.validation, 
                phone_name_helper_text: this.assertIsPhoneNameValid(phone_name)}}, 
                () => this.debouncedHandleChange()))
        
    }
    assertIsPhoneNameValid = (val) => {
        if (phone_is_empty_with_space_excluding(val)){
            return "Поле должно содержать хотя бы 3 символа"
        }
    }
    setPhoneWithValidation = (val) => {
        let phone = this.state.phone
        let phone_helper_text = this.state.validation.phone_helper_text
        if (val){
            if (phone_has_only_number_after_plus(val)){
                if (val.length === 12 && phone_has_full_prefix(val)){
                    phone = val
                    phone_helper_text = ""
                }
                else if (val.length < 12){
                    if (phone_has_full_prefix(val)){
                        phone = val
                    }
                    else if(phone_has_only_plus(val)){
                        phone = val.replace("+", "+7")
                    }
                    else if (phone_has_no_prefix(val)){
                        phone = "+7" + val.slice(1)
                    }
                }
                else if (val.length > 12){
                    // setModalPhoneHelperText("Не более 12 символов")
                }
                else{
                    phone_helper_text = "Введите номер в формате +7XXXXXXXXXX"
                }
            }

        }
        else{
            phone = "+7" + val
            this.setState({phone: phone})
        }
        return this.setState({
            phone: phone,
            validation: {
                ...this.state.validation, phone_helper_text: phone_helper_text
            }
        }, () => this.debouncedHandleChange())
    }
    setIsMain = () => {
        this.setState({is_main: !this.state.is_main})
    }
    render(){
        return (
            <Dialog onClose={this.handleClose} open={this.props.toggled} style={{ width: "28%", marginLeft: "8%" }}>
                <Grid p={2} py={4} container gap={2}>
                    <Grid item container md={12} xs={12} lg={12} spacing={1} style={{ minHeight: "7vh"}}>
                        <Grid item>
                            <TextLabel>Название: </TextLabel>
                        </Grid>
                        <Grid item>
                            <TextField
                            id="modal_phone_name"
                            onChange={(e) => {this.setPhoneNameWithValidation(e.target.value)}}
                            value={this.state.phone_name}
                            onBlur={() => this.handlePhoneNameOnBlur()}
                            helperText={this.state.validation.phone_name_helper_text}
                            inputProps={{
                                style: {height: "15px"}
                            }}
                            size="small"
                            />
                        </Grid>
                    </Grid>
                    <Grid item md={12} xs={12} lg={12}>
                        <Divider style={{ border: "1px solid #DBDBDB" }} />
                    </Grid>
                    <Grid item container xs={12} lg={12} md={12} spacing={1} style={{ minHeight: "7vh"}}>
                        <Grid xs={5} lg={5} md={5} item>
                            <TextLabel>Номер телефона: </TextLabel>
                        </Grid>
                        <Grid xs={6} lg={6} md={6} item>
                            <TextField
                            id="modal_phone_number"
                            inputProps={{
                                style: {
                                  height: "15px",
                                }
                            }}
                            size="small"
                            helperText={this.state.phone_helper_text}
                            onChange={(e) => {this.setPhoneWithValidation(e.target.value)}}
                            value={this.state.phone}
                            >
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid item md={12} xs={12} lg={12}>
                        <Divider style={{ border: "1px solid #DBDBDB" }} />
                    </Grid>
                    <Grid textAlign="center" item xs={12} lg={12} md={12}>
                        <Button 
                        disabled={!this.state.is_valid}
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none", fontSize: "16px" }}                    
                        onClick={() => {
                            // props.addPhone(modal_phone, modal_phone_name, is_main, props.subject)
                            this.handleConfirm();
                        }}
                        >
                        Добавить
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
        )
    }
}